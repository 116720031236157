import { LatLngExpression } from "leaflet";
import styled from "styled-components";

interface LegendProps {
  concaveHullLatLng: any[][] | LatLngExpression[];
  minIntensity: number;
  maxIntensity: number;
  isOdour: boolean;
}

export default function Legend({
  minIntensity,
  maxIntensity,
  isOdour,
}: LegendProps) {
  const Container = styled.div`
    z-index: 499;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    bottom: 0;
    left: 52.6%;
    transform: translate(-50%, -100%);

    @media (min-width: 1440px) {
      left: 51.9%;
    }

    @media (min-width: 1920px) {
      left: 51.4%;
    }
  `;

  const Box = styled.div`
    height: 18px;
    width: 85px;
    /* border: 1px solid white; */
  `;

  const Text = styled.p`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: -42px;
    left: 0px;
    padding: 0px;
    /* font-weight: 700; */
  `;

  const Measurement = styled(Text)`
    top: -18px;
    left: 5px;
  `;

  // Define the color gradient
  const gradientColors = [
    "rgba(20, 15, 105,0.5)",
    "rgba(16, 76, 84,0.5)",
    "rgba(11, 143, 44,0.5)",
    "rgba(37, 221, 27,0.5)",
    "rgba(92, 204, 6,0.5)",
    "rgba(230, 194, 36,0.5)",
    "rgba(236, 118, 30,0.5)",
    "rgba(184, 57, 42,0.5)",
  ];

  const intensityValues = [
    Number(minIntensity).toExponential(2),
    (0.25 * Number(maxIntensity)).toExponential(2),
    (0.55 * Number(maxIntensity)).toExponential(2),
    (0.85 * Number(maxIntensity)).toExponential(2),
    Number(maxIntensity).toExponential(2),
  ];

  return (
    <Container id="Container">
      {gradientColors.map((range, index) => (
        <Box key={index} style={{ backgroundColor: gradientColors[index] }} />
      ))}
      <Box
        style={{
          width: "680px",
          bottom: "-9px",
          left: "0px",
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {intensityValues.map((range, index) => (
          <Text>
            {index === intensityValues.length - 1 ? (
              <p className="last">{intensityValues[index]}</p>
            ) : (
              <p>{intensityValues[index]}</p>
            )}
          </Text>
        ))}
      </Box>
      <Measurement className="measurement">
        {isOdour ? "ou/m³ (odor unit)" : "g/m³"}
      </Measurement>
    </Container>
  );
}
