import { DateTimePicker as MuiDatePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { Dispatch, SetStateAction } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";

interface DatePickerProps {
  label: string;
  setDate: Dispatch<SetStateAction<any>>;
  value?: any;
  disabled?: boolean;
}

export default function DatePicker({
  label,
  setDate,
  value,
  disabled,
}: DatePickerProps) {
  return (
    <Box pb={2}>
      <MuiDatePicker
        sx={{
          width: "100%",
          "& .MuiInputLabel-root.Mui-focused": {
            color: "rgba(255, 255, 255, 0.7)",
          }, //styles the label
          "& .MuiOutlinedInput-root": {
            // minHeight: { xl: "39px", lg: "33px" },
            minHeight: { xs: "33px" },
            "&:hover > fieldset": { borderColor: "rgba(255, 255, 255, 0.7)" },
          },
          input: {
            // padding: { xl: "8px", lg: "5px 8px" },
            padding: { xs: "5px 8px" },
            fontSize: "8px",
          },
        }}
        label={label}
        ampm={false}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        value={value ? dayjs(value) : null}
        slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
        onChange={(newValue) => setDate(newValue)}
        disabled={disabled}
      />
    </Box>
  );
}
