import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface Facilities {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  zone: number;
  lat: number;
  lon: number;
  created_by: number;
  updated_by: number;
}

const selectFacilitie: number = -1;

// Define the initial state using that type
const facility: Facilities[] = [
  {
    id: "",
    created_at: "",
    updated_at: "",
    name: "",
    description: "",
    zone: 0,
    lat: 0,
    lon: 0,
    created_by: 0,
    updated_by: 0,
  },
];

export const facilitiesSlice = createSlice({
  name: "facilities",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: { facilitiesStore: facility, selectFacilitie: selectFacilitie },
  reducers: {
    updateFacilities: (state, action: PayloadAction<Facilities[]>) => {
      state.facilitiesStore = action.payload;
    },
    updateSelectFacilitie: (state, action: PayloadAction<number>) => {
      state.selectFacilitie = action.payload;
    },
  },
});

export const { updateFacilities, updateSelectFacilitie } =
  facilitiesSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default facilitiesSlice.reducer;
