import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SnackbarState {
  isOpen: boolean;
  snackbarData: {
    message: string;
    severity: string;
  };
}

const initialState: SnackbarState = {
  isOpen: false,
  snackbarData: {
    message: "",
    severity: "",
  },
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackBarOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setSnackbarData: (
      state,
      action: PayloadAction<{ message?: string; severity?: string }>
    ) => {
      state.snackbarData = {
        ...state.snackbarData,
        ...action.payload,
      };
    },
  },
});

export const { setSnackBarOpen, setSnackbarData } = snackbarSlice.actions;

export default snackbarSlice.reducer;
