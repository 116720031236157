import { Client } from "./Client";

export class PlacesService {
  constructor(private client: Client) {}

  async getPlaces(facilityID: string) {
    return await this.client.getPlaces(facilityID);
  }

  async getPlacesByID(placeID: string) {
    return await this.client.getPlacesByID(placeID);
  }

  async postPlace(place: any) {
    return await this.client.postPlace(place);
  }

  async getTypes() {
    return await this.client.getTypes();
  }

  async patchPlace(placeID: string, place: any) {
    return await this.client.patchPlace(placeID, place);
  }

  async deletePlace(placeID: string) {
    return await this.client.deletePlace(placeID);
  }
}
