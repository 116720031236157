import { useEffect } from "react";
import { useMap } from "react-leaflet";
import HeatmapOverlay from "leaflet-heatmap";
import { useLeafletContext } from "@react-leaflet/core";
import { useAppSelector } from "../../app/hooks";

interface UTMPoint {
  lat: number;
  lon: number;
  c: number;
}

export default function HeatmapLayer() {
  const map = useMap();
  const context = useLeafletContext();
  const container = (context.layerContainer as any) || context.map;
  let heatMapLayer: any;

  // Find the Layer that is the Heatmap
  function isHeatmapLayer(element: any) {
    return Object.values(element.options).length === 0;
  }

  function deleteHeatmapLayer() {
    let heatMap = Object.values(container._layers).filter(isHeatmapLayer);
    container.removeLayer(heatMap[0]);
  }

  function updateLegend(data: { min: string; max: string; gradient: {} }) {
    var blue = document.querySelector("#blue");
    var green = document.querySelector("#green");
    var yellow = document.querySelector("#yellow");
    var legendCanvas = document.createElement("canvas");
    legendCanvas.width = 680;
    legendCanvas.height = 18;
    var min = document.querySelector("#min");
    var max = document.querySelector("#max");
    var gradientImg: any = document.querySelector("#gradient");
    var legendCtx = legendCanvas.getContext("2d");
    var gradientCfg: any = {};

    // the onExtremaChange callback gives us min, max, and the gradientConfig
    // so we can update the legend
    if (min && max && blue && green && yellow && legendCtx && gradientImg) {
      min.innerHTML = Number(data.min).toExponential(2);
      blue.innerHTML = (0.25 * Number(data.max)).toExponential(2);
      green.innerHTML = (0.55 * Number(data.max)).toExponential(2);
      yellow.innerHTML = (0.85 * Number(data.max)).toExponential(2);
      max.innerHTML = Number(data.max).toExponential(2);
      // regenerate gradient image
      if (data.gradient !== gradientCfg) {
        gradientCfg = data.gradient;
        var gradient = legendCtx.createLinearGradient(0, 0, 680, 1);
        for (var key in gradientCfg) {
          gradient.addColorStop(Number(key), gradientCfg[key]);
        }
        legendCtx.fillStyle = gradient;
        legendCtx.fillRect(0, 0, 680, 18);
        gradientImg.src = legendCanvas.toDataURL();
      }
    }
  }

  heatMapLayer = Object.values(container._layers).filter(isHeatmapLayer);

  const { plume, plumeNumber } = useAppSelector((state) => state.plume);
  var pointsPlume;

  useEffect(() => {
    if (plume && plumeNumber !== -1 && plume.result[plumeNumber]) {
      pointsPlume = plume.result[plumeNumber].plume as UTMPoint[];
      if (pointsPlume) {
        let minCount = plume.result[plumeNumber].min;
        let maxCount = plume.result[plumeNumber].max;

        let avgCount =
          pointsPlume.reduce((sum, point) => sum + point.c, 0) /
          pointsPlume.length;
        let plumeWithRadius = pointsPlume.map((point) => {
          const rangeStart =
            pointsPlume.length < 1000 ? 0.05 * maxCount : 0.9 * maxCount;
          const rangeEnd = maxCount;
          const isInRange = point.c >= rangeStart && point.c <= rangeEnd;
          let radius = 0;
          if (pointsPlume.length < 1000) {
            radius = 0.03;
          }
          if (isInRange) {
            radius = pointsPlume.length < 1000 ? 0.0085 : 0.03;
          } else if (pointsPlume.length < 1000) {
            radius = 0.025;
          } else {
            radius = point.c > avgCount ? 0.07 : 0.03;
          }
          return {
            ...point,
            radius: radius,
          };
        });

        var testData = {
          max: maxCount,
          min: minCount,
          data: plumeWithRadius,
        };

        var cfg = {
          // radius should be small ONLY if scaleRadius is true (or small radius is intended)
          // if scaleRadius is false it will be the constant radius used in pixels
          radius: 0.042,
          maxOpacity: 0.8,
          // gradient: {
          //   0.25: "#0c0887",
          //   0.55: "#7d03a8",
          //   0.85: "#cb4679",
          //   1.0: "#f89441",
          // },

          // scales the radius based on map zoom
          scaleRadius: true,
          // if set to false the heatmap uses the global maximum for colorization
          // if activated: uses the data maximum within the current map boundaries
          //   (there will always be a red spot with useLocalExtremas true)
          useLocalExtrema: false,
          // which field name in your data represents the latitude - default "lat"
          latField: "lat",
          // which field name in your data represents the longitude - default "lng"
          lngField: "lon",
          // which field name in your data represents the data value - default "value"
          valueField: "c",
          container: document.querySelector(".heatmap"),
          // onExtremaChange will be called whenever there's a new maximum or minimum
          onExtremaChange: function (data: {
            min: string;
            max: string;
            gradient: {};
          }) {
            updateLegend(data);
          },
        };

        var heatmapLayer = new HeatmapOverlay(cfg);

        if (testData) {
          heatmapLayer.setData(testData);
          map.addLayer(heatmapLayer);
          if (heatMapLayer[0]) {
            container.removeLayer(heatMapLayer[0]);
          }
        }

        return () => {
          deleteHeatmapLayer();
        };
      }
    }
    // eslint-disable-next-line
  }, [heatMapLayer, pointsPlume]);

  return <></>;
}
