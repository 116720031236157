import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface HeaderProps {
  stage: number;
  selected: number;
  facilitiesStore: any;
  setStage: Dispatch<SetStateAction<number>>;
  fetchForecast: (period: string) => void;
  dispatch: Dispatch<any>;
  setLoading: any;
}

function Header({
  stage,
  selected,
  facilitiesStore,
  setStage,
  fetchForecast,
  dispatch,
  setLoading,
}: HeaderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "15px",
      }}
    >
      {stage > 0 && (
        <IconButton
          size="large"
          aria-label="Back"
          onClick={() => {
            setStage(stage - 1);
            if (stage - 1 === 1) {
              dispatch(setLoading(true));
              fetchForecast("days");
            }
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Typography variant="h6" component="h2" fontWeight="bold">
        {stage > 0 && selected !== -1
          ? `Forecasting: ${facilitiesStore[selected].name}`
          : "Forecasting"}
      </Typography>
    </Box>
  );
}

export default Header;
