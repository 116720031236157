import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const placesUpdated: boolean = false;

const placesSlice = createSlice({
  name: "places",
  initialState: { placesUpdated },
  reducers: {
    setPlacesUpdated: (state, action: PayloadAction<boolean>) => {
      state.placesUpdated = action.payload;
    },
  },
});

export const { setPlacesUpdated } = placesSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default placesSlice.reducer;
