interface MarkerProps {
  fill: string;
}

const Marker = (props: MarkerProps) => (
  <svg viewBox="0 0 60 90" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill={props.fill} />
    <path d="M30 90L4.01924 45L55.9808 45L30 90Z" fill={props.fill} />
  </svg>
);

export const CenterMarker = (props: MarkerProps) => (
  <svg
    viewBox="-20 0 100 100"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill={props.fill} />
    <path d="M30 90L4.01924 45L55.9808 45L30 90Z" fill={props.fill} />
  </svg>
);

export default Marker;
