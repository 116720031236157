import { Dispatch, SetStateAction, useEffect } from "react";
import ForecastingCard from "./ForecastingCard";
import HourlyForecastTable from "./HourlyForecastTable";
import styled from "styled-components";
import { Forecast } from "../../../interfaces/Forecast";

interface ForecastDisplayProps {
  stage: number;
  isLoading: boolean;
  forecastDay: string | undefined;
  forecast: Forecast | undefined;
  keys: string[] | undefined;
  fetchForecast: (period: string) => void;
  setForecastDay: Dispatch<SetStateAction<string | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &.hourly {
    grid-template-columns: 1fr;
  }
`;

function ForecastDisplay({
  stage,
  isLoading,
  forecastDay,
  forecast,
  keys,
  fetchForecast,
  setForecastDay,
  setOpen,
}: ForecastDisplayProps) {
  return (
    <StyledGrid className={stage === 2 ? "hourly" : ""}>
      {!isLoading && stage === 2
        ? forecastDay && (
            <HourlyForecastTable
              forecastDay={forecastDay}
              forecast={forecast}
              setModalOpen={setOpen}
            />
          )
        : !isLoading &&
          forecast &&
          stage === 1 &&
          keys &&
          keys
            .slice(0, 3)
            .map((key: any) => (
              <ForecastingCard
                dateKey={key}
                forecastData={forecast[key]}
                fetchForecastData={fetchForecast}
                setForecastDay={setForecastDay}
              />
            ))}
    </StyledGrid>
  );
}

export default ForecastDisplay;
