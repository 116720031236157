import { Grid } from "@mui/material";
import StyledTextField from "../../StyledTextField";
import { useEffect } from "react";

interface PointProps {
  emissionSource: any;
  setEmissionSource: any;
  id: string | undefined;
}

function Areapoly({ emissionSource, setEmissionSource, id }: PointProps) {
  useEffect(() => {
    console.log(emissionSource.typeData);
  }, [emissionSource]);

  return (
    <>
      <Grid item xs={6}>
        <StyledTextField
          label="Aremis - Area Emission Rate (g/(s-m2))"
          type="number"
          value={emissionSource.typeData?.aremis}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                aremis: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Relhgt - Release Height (m)"
          type="number"
          value={emissionSource.typeData?.relhgt}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                relhgt: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Szinit - Initial Vertical Dimension (m)"
          type="number"
          value={emissionSource.typeData?.szinit}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                szinit: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
    </>
  );
}

export default Areapoly;
