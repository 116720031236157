import {
  LayersControl as LeafletLayersControl,
  TileLayer,
} from "react-leaflet";

export default function LayersControl() {
  return (
    <LeafletLayersControl position="bottomright">
      <LeafletLayersControl.Overlay name="ArcGIS">
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
      </LeafletLayersControl.Overlay>
      <LeafletLayersControl.Overlay checked name="Google">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
      </LeafletLayersControl.Overlay>
      <LeafletLayersControl.Overlay name="OpenStreetMap">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LeafletLayersControl.Overlay>
    </LeafletLayersControl>
  );
}
