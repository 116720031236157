import { Box, Button, Grid, TextareaAutosize } from "@mui/material";
import StyledTextField from "../../StyledTextField";
import Dropdown from "../../../Dropdown/Dropdown";
import BasicDatePicker from "../../../DatePicker/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import { setLoading } from "../../../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../../features/snackbar/snackbar";
import { useServices } from "../../../../context/ServicesContext";
import { formatISO, parseISO } from "date-fns";
import MultiOptDropdown from "../../../Dropdown/MultiOptDropdown";
import { update } from "../../../../features/refineries/refinerySlice";
import { setOccurrenceUpdated } from "../../../../features/occurrence/occurrenceSlice";

interface OccurrenceFieldsProps {
  setOpen: (value: boolean) => void;
  occurrenceID: number;
  facility: number;
  fetchOccurrences: () => void;
  setNewState: (value: boolean) => void;
}

interface Occurrence {
  name: string;
  lat: string;
  lon: string;
  facility: string;
  user_type: number;
  intensity: number;
  justification_type: number;
  start_at: any;
  end_at: any;
  description: string;
  sources: any;
}

function OccurrenceFields({
  setOpen,
  facility,
  occurrenceID,
  fetchOccurrences,
  setNewState,
}: OccurrenceFieldsProps) {
  const dispatch = useAppDispatch();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { occurrenceUpdated } = useAppSelector((state) => state.occurrence);
  const { occurrenceService, facilitiesService } = useServices();
  const [selectedUserType, setSelectedUserType] = useState<number>(-1);
  const [selectedIntensity, setSelectedIntensity] = useState<number>(-1);
  const [selectedJustificationType, setJustificationType] =
    useState<number>(-1);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [checkmarkSelected, setCheckmarksSelected] = useState<any>([]);
  const [emissionSources, setEmissionSources] = useState<any>();
  const [occurrence, setOccurrence] = useState<Occurrence>({
    name: "",
    lat: "",
    lon: "",
    facility: "-1",
    user_type: -1,
    intensity: -1,
    justification_type: -1,
    start_at: null,
    end_at: null,
    description: "",
    sources: [],
  });

  const userTypes = [
    { name: "External User" },
    { name: "User" },
    { name: "Sensitive Receptor" },
  ];

  const intensities = [
    { name: "1 - Very Weak" },
    { name: "2 - Weak" },
    { name: "3 - Distinct" },
    { name: "4 - Strong " },
    { name: "5 - Very Strong" },
    { name: "6 - Extremely Strong" },
  ];

  const justificationTypes = [
    { name: "Manual Justification" },
    { name: "Automatic Justification" },
  ];

  async function getOccurrence() {
    await occurrenceService
      .getOccurrenceByID(occurrenceID)
      .then((resp) => {
        setOccurrence(resp);
        setSelectedUserType(
          userTypes.findIndex(
            (userType) =>
              userType.name.toUpperCase().replace(/\s+/g, "_") ===
              resp.user_type
          )
        );
        setStartDate(new Date(resp.start_at));
        setEndDate(new Date(resp.end_at));
        setCheckmarksSelected(
          emissionSources
            .filter((emission: any) => resp.sources.includes(emission.id))
            .map((emission: any) => emission.name)
        );
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  useEffect(() => {
    if (occurrenceID !== null && emissionSources !== undefined) {
      dispatch(setLoading(true));
      getOccurrence();
      return () => {
        setOccurrence({
          name: "",
          lat: "",
          lon: "",
          facility: "-1",
          user_type: -1,
          intensity: -1,
          justification_type: -1,
          start_at: null,
          end_at: null,
          description: "",
          sources: [],
        });
        setSelectedUserType(-1);
        setSelectedIntensity(-1);
        setJustificationType(-1);
        setStartDate(null);
        setEndDate(null);
      };
    }
  }, [emissionSources]);

  async function fetchEmissionSource(facilityID: string) {
    if (facilityID) {
      dispatch(setLoading(true));
      let resp = await facilitiesService
        .fetchEmissionSource(facilityID)
        .then((resp) => {
          setEmissionSources(resp.data);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  function getSelectedEmissionIDs() {
    if (emissionSources && checkmarkSelected) {
      const selectedEmissionIDs = checkmarkSelected
        .map((selectedName: any) => {
          const selectedEmission = emissionSources.find(
            (emission: { name: any }) => emission.name === selectedName
          );
          return selectedEmission ? selectedEmission.id : null;
        })
        .filter((id: null) => id !== null);

      return {
        sources: selectedEmissionIDs,
      };
    }

    return { sources: [] }; // Return an empty array in the specified structure
  }

  useEffect(() => {
    fetchEmissionSource(facilitiesStore[facility].id);
  }, []);

  useEffect(() => {
    if (facility !== -1) {
      setOccurrence((prevOccurrence: any) => ({
        ...prevOccurrence,
        facility: facilitiesStore[facility].id,
      }));
    }
    if (selectedUserType !== -1) {
      setOccurrence((prevOccurrence: any) => ({
        ...prevOccurrence,
        user_type: userTypes[selectedUserType].name
          .toUpperCase()
          .replace(/\s+/g, "_"),
      }));
    }
    if (selectedIntensity !== -1) {
      setOccurrence((prevEmissionSource: any) => ({
        ...prevEmissionSource,
        intensity: selectedIntensity,
      }));
    }
    if (selectedJustificationType !== -1) {
      setOccurrence((prevEmissionSource: any) => ({
        ...prevEmissionSource,
        justification_type: selectedJustificationType,
      }));
    }
    if (checkmarkSelected !== null) {
      setOccurrence((prevEmissionSource: any) => ({
        ...prevEmissionSource,
        ...getSelectedEmissionIDs(),
      }));
    }
  }, [
    facility,
    selectedUserType,
    selectedIntensity,
    selectedJustificationType,
    checkmarkSelected,
  ]);

  return (
    <>
      <Grid item xs={6}>
        <StyledTextField
          label="Name"
          InputLabelProps={{
            shrink: true,
          }}
          value={occurrence?.name}
          onChange={(e) =>
            setOccurrence({ ...occurrence, name: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Dropdown
          className="user-type"
          label={"User type"}
          options={userTypes}
          checkmarks={false}
          selected={selectedUserType}
          setSelected={setSelectedUserType}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Latitude"
          type="number"
          value={occurrence?.lat}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOccurrence({ ...occurrence, lat: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Longitude"
          type="number"
          value={occurrence?.lon}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOccurrence({ ...occurrence, lon: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Dropdown
          className="intensity"
          label={"Intensity"}
          options={intensities}
          checkmarks={false}
          selected={
            occurrence?.intensity !== -1
              ? occurrence.intensity
              : selectedIntensity
          }
          setSelected={setSelectedIntensity}
        />
      </Grid>
      <Grid item xs={4}>
        <Dropdown
          className="justification-type"
          label={"Justification type"}
          options={justificationTypes}
          checkmarks={false}
          selected={
            occurrence?.justification_type !== -1
              ? occurrence.justification_type
              : selectedJustificationType
          }
          setSelected={setJustificationType}
        />
      </Grid>
      <Grid item xs={4}>
        <MultiOptDropdown
          label="Emission Source"
          options={emissionSources}
          checkmarkSelected={checkmarkSelected}
          setCheckmarksSelected={setCheckmarksSelected}
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          label="Start at"
          value={occurrence?.start_at ? parseISO(occurrence?.start_at) : null}
          setDate={(date) =>
            setOccurrence({
              ...occurrence,
              start_at: formatISO(date?.$d),
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          label="End at"
          value={occurrence?.end_at ? parseISO(occurrence?.end_at) : null}
          setDate={(date) =>
            setOccurrence({
              ...occurrence,
              end_at: formatISO(date?.$d),
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="Description"
          value={occurrence?.description}
          textarea={true}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setOccurrence({ ...occurrence, description: e.target.value })
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            maxHeight: "30px",
            fontSize: "10px",
            borderRadius: "18px",
            fontWeight: "700",
            marginBottom: "9px",
            marginRight: "auto",
            color: "rgba(0, 0, 0, 0.87)",
          }}
          color="error"
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        {occurrenceID !== null ? (
          <Button
            sx={{
              maxHeight: "30px",
              fontSize: "10px",
              borderRadius: "18px",
              fontWeight: "700",
              margin: "0 0 9px 9px",
            }}
            variant="contained"
            onClick={async () => {
              dispatch(setLoading(true));
              await occurrenceService
                .patchOccurrence(occurrenceID, occurrence)
                .then(() => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({
                      message: "Occurrence edited successfully",
                      severity: "success",
                    })
                  );
                  fetchOccurrences();
                  dispatch(setOccurrenceUpdated(!occurrenceUpdated));
                  setNewState(false);
                })
                .catch((err: { message: any }) => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({
                      message: err.message,
                      severity: "error",
                    })
                  );
                  dispatch(setLoading(false));
                });
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            sx={{
              maxHeight: "30px",
              fontSize: "10px",
              borderRadius: "18px",
              fontWeight: "700",
              margin: "0 0 9px 9px",
            }}
            variant="contained"
            onClick={async () => {
              dispatch(setLoading(true));
              await occurrenceService
                .postOccurrence(occurrence)
                .then(() => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({
                      message: "Occurrence created successfully",
                      severity: "success",
                    })
                  );
                  fetchOccurrences();
                  dispatch(setOccurrenceUpdated(!occurrenceUpdated));
                  setNewState(false);
                })
                .catch((err: { message: any }) => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({
                      message: err.message,
                      severity: "error",
                    })
                  );
                  dispatch(setLoading(false));
                });
            }}
          >
            Save
          </Button>
        )}
      </Grid>
    </>
  );
}

export default OccurrenceFields;
