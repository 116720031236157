import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

export class Client {
  private axios!: AxiosInstance;

  constructor(url: string, private getToken: () => string | undefined) {
    this.setup(url);
  }

  setup(url: string) {
    const axios = Axios.create({
      baseURL: url,
    });

    axios.interceptors.request.use(this.intercept.bind(this)); // Bind the intercept function to the instance of the Client class
    // Add a response interceptor to refresh the JWT token if it's expired
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          sessionStorage.getItem("refresh_token")
        ) {
          const refreshToken = sessionStorage.getItem("refresh_token");
          return this.axios
            .post("/token/refresh/", { refresh: refreshToken })
            .then(({ data }: AxiosResponse) => {
              sessionStorage.setItem("access_token", data.access);
              originalRequest.headers.Authorization = `Bearer ${data.access}`;
              return this.axios(originalRequest);
            })
            .catch((err) => {
              const { response }: { response?: AxiosResponse } =
                err as AxiosError;

              if (
                response?.status === 401 &&
                response?.statusText === "Unauthorized"
              ) {
                sessionStorage.removeItem("access_token");
                sessionStorage.removeItem("refresh_token");
                window.location.href = "/login";
              }
              throw new Error(
                "Error: " +
                  (response?.data?.error !== undefined
                    ? response.data.error
                    : response?.statusText)
              );
            });
        }
        return Promise.reject(error);
      }
    );
    this.axios = axios;
  }

  private intercept = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    try {
      const token = this.getToken();
      if (token) {
        config.headers.set("Content-Type", "application/json");
        config.headers.Authorization = `Bearer ${token}`;
        config.timeout = 0;
      }
      return config;
    } catch (e) {
      throw e;
    }
  };

  async login(username: string, password: string): Promise<any> {
    try {
      const { data } = await this.axios.post("/token/", {
        username,
        password,
      });
      return data;
    } catch (error) {
      const { response } = error as AxiosError;
      throw new Error("Error: " + response?.statusText);
    }
  }

  async fetchFacilities(): Promise<any> {
    try {
      const { data } = await this.axios.get("/facilities/");
      return data;
    } catch (error) {
      const { response } = error as AxiosError;
      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error("Error: " + response?.statusText);
    }
  }

  async postFacility(facility: any): Promise<any> {
    try {
      const { data } = await this.axios.post("/facilities/", facility);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async patchFacility(facilityID: string, facility: any): Promise<any> {
    try {
      const { data } = await this.axios.patch(
        `/facilities/${facilityID}/`,
        facility
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async deleteFacility(facilityID: string): Promise<any> {
    try {
      const { data } = await this.axios.delete(`/sources/${facilityID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async fetchEmissionSource(facilityID: string): Promise<any> {
    try {
      const { data } = await this.axios.get(
        "/facilities/" + facilityID + "/sources/"
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async fetchStations(facilityID: string): Promise<any> {
    try {
      const { data } = await this.axios.get(
        "/facilities/" + facilityID + "/stations/"
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async postEmissionSource(emissionSource: any): Promise<any> {
    try {
      const { data } = await this.axios.post("/sources/", emissionSource);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;
      console.log(response);
      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getEmissionSource(emissionSourceID: string): Promise<any> {
    try {
      const { data } = await this.axios.get(`/sources/${emissionSourceID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async patchEmissionSource(
    emissionSourceID: string,
    emissionSource: any
  ): Promise<any> {
    try {
      const { data } = await this.axios.patch(
        `/sources/${emissionSourceID}/`,
        emissionSource
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async deleteEmissionSource(emissionSourceID: string): Promise<any> {
    try {
      const { data } = await this.axios.delete(`/sources/${emissionSourceID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async fetchPlume(
    facilitiesID: string,
    sources: { sources: [] },
    startDate?: string,
    endDate?: string,
    avertime?: number,
    hours?: number
  ): Promise<any> {
    let url = "/facilities/" + facilitiesID + "/module-plumes/?";
    if (startDate) {
      url += "date_start=" + startDate + "&";
    }
    if (endDate) {
      url += "date_end=" + endDate + "&";
    }
    if (avertime) {
      url += "avertime=" + avertime;
    }
    if (hours) {
      url += "&hours=" + hours;
    }

    try {
      const { data } = await this.axios.post(url, sources);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getPlumeDocs(plumeID: string): Promise<any> {
    try {
      const response = await this.axios.get(`/plumes/${plumeID}/plume-docs/`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "plume-docs.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;
      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async fetchFacilityForecast(
    facilityID: string,
    period: string
  ): Promise<any> {
    try {
      const { data } = await this.axios.get(
        "/weather/facility/?facility=" + facilityID + "&period=" + period
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getOccurrence(facilityID: any): Promise<any> {
    try {
      const { data } = await this.axios.get(
        "/facilities/" + facilityID + "/occurrences/"
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async postOccurrence(occurrence: any): Promise<any> {
    try {
      const { data } = await this.axios.post("/occurrences/", occurrence);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getOccurrenceByID(occurrenceID: number): Promise<any> {
    try {
      const { data } = await this.axios.get(`/occurrences/${occurrenceID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async patchOccurrence(occurrenceID: number, occurrence: any): Promise<any> {
    try {
      const { data } = await this.axios.patch(
        `/occurrences/${occurrenceID}/`,
        occurrence
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async deleteOccurrence(occurrenceID: number): Promise<any> {
    try {
      const { data } = await this.axios.delete(`/occurrences/${occurrenceID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async postPlace(place: any): Promise<any> {
    try {
      const { data } = await this.axios.post("/places/", place);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getTypes(): Promise<any> {
    try {
      const { data } = await this.axios.get("/places/type/");
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getPlaces(facilityID: any): Promise<any> {
    try {
      const { data } = await this.axios.get(
        "/facilities/" + facilityID + "/places/"
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async getPlacesByID(placeID: any): Promise<any> {
    try {
      const { data } = await this.axios.get(`/places/${placeID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async deletePlace(placeID: string): Promise<any> {
    try {
      const { data } = await this.axios.delete(`/places/${placeID}/`);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async patchPlace(placeID: string, place: any): Promise<any> {
    try {
      const { data } = await this.axios.patch(`/places/${placeID}/`, place);
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }

  async resolveOccurrence(occurrenceID: number, occurrence: any): Promise<any> {
    try {
      const { data } = await this.axios.post(
        `/occurrences/${occurrenceID}/resolve/`,
        occurrence
      );
      return data;
    } catch (error) {
      const { response }: { response?: AxiosResponse } = error as AxiosError;

      if (response?.status === 401 && response?.statusText === "Unauthorized") {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
      throw new Error(
        "Error: " +
          (response?.data?.error !== undefined
            ? response.data.error
            : response?.statusText)
      );
    }
  }
}
