import { Client } from "./Client";

export class PlumeService {
  constructor(private client: Client) {}

  async fetchPlume(
    facilitiesID: string,
    sources: { sources: any },
    startDate?: string,
    endDate?: string,
    avertime?: number,
    hours?: number
  ) {
    return await this.client.fetchPlume(
      facilitiesID,
      sources,
      startDate,
      endDate,
      avertime,
      hours
    );
  }

  async getPlumeDocs(plumeID: string) {
    return await this.client.getPlumeDocs(plumeID);
  }
}
