import { CircularProgress, Container } from "@mui/material";
import { useAppSelector } from "../../app/hooks";

export default function Loader() {
  const { isLoading } = useAppSelector((state) => state.loading);

  return isLoading ? (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
          content: '""',
          zIndex: 1400,
          display: "block",
          position: "absolute",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          background: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <CircularProgress
        sx={{
          zIndex: 1400,
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    </Container>
  ) : (
    <></>
  );
}
