import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const occurrenceUpdated: boolean = false;

const occurrenceSlice = createSlice({
  name: "occurrence",
  initialState: { occurrenceUpdated },
  reducers: {
    setOccurrenceUpdated: (state, action: PayloadAction<boolean>) => {
      state.occurrenceUpdated = action.payload;
    },
  },
});

export const { setOccurrenceUpdated } = occurrenceSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default occurrenceSlice.reducer;
