import { TextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import styled from "styled-components";

const TextFieldStyle = styled(TextField)`
  width: 100%;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 16px !important;
  .MuiInputLabel-root {
    &.Mui-focused {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .MuiInputBase-input {
    padding: 12px 8px;
    font-size: 10px;
  }
  .MuiOutlinedInput-root {
    padding: 0;
    &.Mui-focused fieldset {
      border-color: rgba(255, 255, 255, 0.7);
    }
  }
`;

type TextFieldProps = MuiTextFieldProps & {
  label: string;
  textarea?: boolean;
};

const StyledTextField: React.FC<TextFieldProps> = (props) => {
  return props.textarea ? (
    <TextFieldStyle
      multiline
      rows={2}
      maxRows={Infinity}
      fullWidth
      {...props}
    />
  ) : (
    <TextFieldStyle {...props} />
  );
};

export default StyledTextField;
