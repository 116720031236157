import { Box, Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { setLoading } from "../../../features/loading/loadingSlice";
import Dropdown from "../../Dropdown/Dropdown";

interface FacilitySelectionProps {
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
  fetchForecast: (period: string) => void;
  dispatch: Dispatch<any>;
  facilitiesStore: any;
}

function FacilitySelection({
  selected,
  setSelected,
  fetchForecast,
  dispatch,
  facilitiesStore,
}: FacilitySelectionProps) {
  return (
    <>
      <Dropdown
        label="Facility"
        options={facilitiesStore}
        checkmarks={false}
        selected={selected}
        setSelected={setSelected}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            maxHeight: "30px",
            fontSize: "10px",
            borderRadius: "18px",
            fontWeight: "700",
          }}
          variant="contained"
          disabled={selected === -1}
          onClick={() => {
            dispatch(setLoading(true));
            fetchForecast("days");
          }}
        >
          Search
        </Button>
      </Box>
    </>
  );
}

export default FacilitySelection;
