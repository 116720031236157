import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import Slider from "../Slider/Slider";
import Weather from "../Weather/Weather";
import BasicDatePicker from "../../../DatePicker/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import MultiOptDropdown from "../../../Dropdown/MultiOptDropdown";
import { updatePlume } from "../../../../features/plume/plumeSlice";
import { useServices } from "../../../../context/ServicesContext";
import {
  addDays,
  addHours,
  differenceInHours,
  format,
  parse,
  set,
} from "date-fns";
import { setLoading } from "../../../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../../features/snackbar/snackbar";
import { Options } from "./Options/Options";
import ModelData from "./ModelData/ModelData";
import { updateSelectFacilitie } from "../../../../features/facilities/facilitiesSlice";
import { ArrowDropUp, ArrowDropDown, HelpOutline } from "@mui/icons-material";
import { setSelectedEmissionSources } from "../../../../features/emissionSource/emissionSourceSlice";
interface MenuBodyProps {
  open: boolean;
  setPlumeStyle: Dispatch<SetStateAction<boolean>>;
  setActiveIcons: Dispatch<SetStateAction<boolean>>;
  plumeStyle: boolean;
  setPlumeDate: Dispatch<SetStateAction<string | undefined>>;
  activeIcons: boolean;
}

export default function MenuBody({
  open,
  setPlumeStyle,
  setActiveIcons,
  plumeStyle,
  setPlumeDate,
  activeIcons,
}: MenuBodyProps) {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<number>(-1);
  const [emissionSources, setEmissionSources] = useState<any>();
  const [groupedEmissionSources, setGroupedEmissionSources] = useState<any>();
  const [checkmarkSelected, setCheckmarksSelected] = useState<any>([]);
  const [timeWindowHook, setTimeWindow] = useState<number>(-1);
  const timeWindow = [
    { name: "10m", value: "10_M" },
    { name: "1hr", value: 1 },
    { name: "8hr", value: 8 },
    { name: "24hr", value: 24 },
  ];
  const { plumeService } = useServices();
  const { facilitiesService } = useServices();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { plume, storedData } = useAppSelector((state) => state.plume);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isAuto, setIsAuto] = useState(false);
  const [lastStep, setLastStep] = useState<number>(1);
  const [sliderValue, setSliderValue] = useState(1);
  const [weatherVisible, setWeatherVisible] = useState(true);
  const [modelDataVisible, setModelDataVisible] = useState(true);
  const [timeframeVisible, setTimeframeVisible] = useState(true);
  const [timeWindowOptions, setTimeWindowOptions] = useState<any>(timeWindow);
  const { emissionSourceUpdated } = useAppSelector(
    (state) => state.emissionSource
  );
  const isFirstTimeAuto = useRef(true);
  const [disableDates, setDisableDates] = useState<boolean>(false);

  useEffect(() => {
    if (startDate && endDate) {
      let formatedEndDate = parse(
        format(endDate?.$d ? endDate?.$d : endDate, "yyyy-MM-dd HH:mm"),
        "yyyy-MM-dd HH:mm",
        new Date()
      );

      let formatedStartDate = parse(
        format(startDate?.$d ? startDate?.$d : startDate, "yyyy-MM-dd HH:mm"),
        "yyyy-MM-dd HH:mm",
        new Date()
      );

      const is24HoursDifference =
        differenceInHours(formatedEndDate, formatedStartDate) > 0 &&
        differenceInHours(formatedEndDate, formatedStartDate) <= 24;
      const is72HoursDifference =
        differenceInHours(formatedEndDate, formatedStartDate) >= 24 &&
        differenceInHours(formatedEndDate, formatedStartDate) <= 72;

      if (isAuto) {
        setTimeWindowOptions(
          timeWindow.filter(
            (option) => option.value !== 8 && option.value !== 24
          )
        );
      } else if (is24HoursDifference && !isAuto) {
        setTimeWindowOptions(
          timeWindow.filter((option) => option.value !== "10_M")
        );
      } else if (is72HoursDifference && !isAuto) {
        setTimeWindowOptions(
          timeWindow.filter(
            (option) => option.value !== 1 && option.value !== "10_M"
          )
        );
      } else {
        setTimeWindowOptions([]);
      }
    } else {
      setTimeWindowOptions([]);
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);

  useEffect(() => {
    if (isAuto && isFirstTimeAuto.current) {
      setStartDate(new Date());
      setEndDate(addHours(new Date(), 1));
      setTimeWindowOptions(
        timeWindow.filter((option) => option.value !== 8 && option.value !== 24)
      );
      isFirstTimeAuto.current = false;
    } else {
      setTimeWindowOptions(
        timeWindow.filter((option) => option.value !== 8 && option.value !== 24)
      );
    }
  }, [isAuto]);

  const plumeDate = useMemo(
    () => setPlumeDate(plume?.result[sliderValue - 1]?.dateStart),
    // eslint-disable-next-line
    [sliderValue]
  );

  useEffect(() => {
    setLastStep(plume?.result?.length);
    if (storedData.time !== 0) {
      setSelected(storedData.facilityPos);
      setTimeWindow(timeWindow.findIndex((e) => e.value === storedData.time));
    }
    dispatch(setLoading(false));
    // eslint-disable-next-line
  }, [plume]);

  async function fetchEmissionSource() {
    dispatch(updateSelectFacilitie(selected));
    if (selected !== -1) {
      dispatch(setLoading(true));
      await facilitiesService
        .fetchEmissionSource(facilitiesStore[selected].id)
        .then((resp) => {
          setEmissionSources(
            resp.data.map((emission: any) => ({
              id: emission.id,
              name: emission.name,
              is_odour: emission.is_odour,
            }))
          );
          setGroupedEmissionSources(
            resp.data.reduce(
              (groups: { [x: string]: any[] }, source: { pollutant: any }) => {
                const key = source.pollutant;
                if (!groups[key]) {
                  groups[key] = [];
                }
                groups[key].push(source);
                return groups;
              },
              {}
            )
          );
          dispatch(setLoading(false));
          if (storedData) {
            setCheckmarksSelected(
              resp.data
                .filter((emission: any) =>
                  storedData.emissionSources.includes(emission.id)
                )
                .map((emission: any) => emission.name)
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  async function fetchPlume() {
    dispatch(setLoading(true));
    await plumeService
      .fetchPlume(
        facilitiesStore[selected].id,
        getSelectedEmissionIDs(),
        startDate !== undefined
          ? format(startDate?.$d ? startDate?.$d : startDate, "yyyy-MM-dd HHmm")
          : undefined,
        endDate !== undefined
          ? format(endDate?.$d ? endDate?.$d : endDate, "yyyy-MM-dd HHmm")
          : undefined,
        timeWindowHook !== -1
          ? timeWindowOptions[timeWindowHook].value
          : undefined
      )
      .then((resp) => {
        if (resp.result.length === 0) {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: "No plume data found",
              severity: "error",
            })
          );
        }
        dispatch(setSelectedEmissionSources(getSelectedEmissionOdor()));
        dispatch(updatePlume(resp));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  useEffect(() => {
    fetchEmissionSource();
    setStartDate(null);
    setEndDate(null);
    setTimeWindow(-1);
    // eslint-disable-next-line
  }, [selected, emissionSourceUpdated, facilitiesStore]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (isAuto && sliderValue === lastStep) {
      let newStartDate = addHours(startDate, 1);
      let newEndDate = addHours(endDate, 1);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setIsAuto(false);
      intervalId = setTimeout(
        () => {
          fetchPlume().then(() => {
            setSliderValue(1);
            setIsAuto(true);
          });
        },
        isAuto && lastStep === 2 ? 3600000 : 600000
      );
    }

    return () => {
      clearTimeout(intervalId as NodeJS.Timeout);
    };
  }, [sliderValue]);

  function getSelectedEmissionIDs() {
    if (emissionSources && checkmarkSelected) {
      const selectedEmissionIDs = checkmarkSelected
        .map((selectedName: any) => {
          const selectedEmission = emissionSources.find(
            (emission: { name: string }) => emission.name === selectedName
          );
          return selectedEmission ? selectedEmission.id : null;
        })
        .filter((id: null) => id !== null);

      return {
        sources: selectedEmissionIDs,
      };
    }

    return { sources: [] }; // Return an empty array in the specified structure
  }

  function getSelectedEmissionOdor() {
    if (emissionSources && checkmarkSelected) {
      const selectedEmissionIDs = emissionSources.filter((emission: any) =>
        checkmarkSelected.includes(emission.name)
      );

      return {
        sources: selectedEmissionIDs,
      };
    }

    return { sources: [] };
  }

  return open ? (
    <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "90vh" }}>
      <Card
        sx={{
          backgroundColor: "#282c2e",
        }}
      >
        <CardHeader
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "#0A161F",
            padding: "5px 15px 5px 15px",
            span: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          title="Weather"
          action={weatherVisible ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() => setWeatherVisible(!weatherVisible)}
        />
        {weatherVisible && (
          <CardContent
            sx={{
              padding: "0px",
              "&:last-child": { paddingBottom: "0" },
              display: "flex",
            }}
          >
            <Weather
              plumeInfo={
                plume ? plume.result[sliderValue - 1]?.meteorology : null
              }
            />
          </CardContent>
        )}
      </Card>
      <Card
        sx={{
          backgroundColor: "#282c2e",
        }}
      >
        <CardHeader
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "#0A161F",
            padding: "5px 15px 5px 15px",
            span: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          title="Start/End"
          action={modelDataVisible ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() => setModelDataVisible(!modelDataVisible)}
        />
        {modelDataVisible && (
          <CardContent
            sx={{
              padding: "10px",
              "&:last-child": { paddingBottom: "5px" },
            }}
          >
            <Dropdown
              label="Facility"
              options={facilitiesStore}
              checkmarks={false}
              selected={selected}
              setSelected={setSelected}
            />
            <MultiOptDropdown
              label="Emission Source"
              groupedOptions={groupedEmissionSources}
              checkmarkSelected={checkmarkSelected}
              setCheckmarksSelected={setCheckmarksSelected}
            />
            <BasicDatePicker
              value={
                storedData.startDate !== "" ? storedData.startDate : startDate
              }
              label="Start"
              setDate={setStartDate}
              disabled={disableDates}
            />
            <BasicDatePicker
              value={storedData.endDate !== "" ? storedData.endDate : endDate}
              label="End"
              setDate={setEndDate}
              disabled={disableDates}
            />
            <Dropdown
              label="Time"
              options={timeWindowOptions}
              disabled={timeWindowOptions.length === 0}
              checkmarks={false}
              selected={timeWindowHook}
              setSelected={setTimeWindow}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: plume ? "space-between" : "flex-end",
              }}
            >
              {plume && plume.success && plume.id && (
                <ModelData plumeId={plume.id} plumeService={plumeService} />
              )}
              <FormControlLabel
                sx={{
                  // maxHeight: { xl: "30px", lg: "20px" },
                  maxHeight: { xs: "20px" },
                  // fontSize: { xl: ":10px", lg: "8px" },
                  fontSize: { xs: "8px" },
                  borderRadius: "18px",
                  fontWeight: "700",
                  marginBottom: "9px",
                }}
                control={
                  <Checkbox
                    onChange={() => {
                      setIsAuto(!isAuto);
                      setTimeframeVisible(isAuto);
                      setDisableDates(!disableDates);
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Auto
                  </Typography>
                }
              />
              <Tooltip
                title={
                  <>
                    <p>Start Date cannot be after End Date</p>
                    <p>
                      The time difference between Start Date and End Date cannot
                      be greater than 72 hours
                    </p>
                    <p>
                      If Start Date and End Date are separated by up to 24 hours
                      it is possible to generate plumes for 1, 8 and 24 hours
                    </p>
                    <p>
                      If Start Date and End Date are separated by up to 72 hours
                      it is possible to generate plumes for 8 and 24 hours
                    </p>
                    <p>Emission sources cannot have different pollutants</p>
                  </>
                }
                sx={{ margin: "0 0 9px auto" }}
                placement="top-start"
              >
                <HelpOutline />
              </Tooltip>
              <Button
                sx={{
                  // maxHeight: { xl: "30px", lg: "20px" },
                  maxHeight: { xs: "20px" },
                  // fontSize: { xl: "10px", lg: "8px" },
                  fontSize: { xs: "8px" },
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                }}
                variant="contained"
                disabled={
                  selected === -1 ||
                  checkmarkSelected.length === 0 ||
                  timeWindowHook === -1
                }
                onClick={() => fetchPlume()}
              >
                Search
              </Button>
            </Box>
          </CardContent>
        )}
      </Card>
      <Card
        sx={{
          backgroundColor: "#282c2e",
          overflow: "visible",
        }}
      >
        <CardHeader
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "#0A161F",
            padding: "5px 15px 5px 15px",
            span: {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
          title="Timeframe Navigation"
          action={timeframeVisible ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() =>
            isAuto ? null : setTimeframeVisible(!timeframeVisible)
          }
        />
        <CardContent
          sx={{
            visibility: !timeframeVisible ? "hidden" : "visible",
            height: !timeframeVisible ? "0px" : "100%",
            padding: "5px 15px",
            "&:last-child": { paddingBottom: "5px" },
          }}
        >
          <Slider
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            lastStep={lastStep ?? 1}
            plumeDate={plume?.result[sliderValue - 1]?.dateStart}
            isAuto={isAuto}
          />
        </CardContent>
      </Card>
      <Options
        setPlumeStyle={setPlumeStyle}
        plumeStyle={plumeStyle}
        setActiveIcons={setActiveIcons}
        activeIcons={activeIcons}
      />
    </div>
  ) : (
    <></>
  );
}
