import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import OccurrenceFields from "./OccurrenceFields/OccurrenceFields";
import FacilitySelection from "./OccurrenceFields/FacilitySelection";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setLoading } from "../../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../features/snackbar/snackbar";
import { useServices } from "../../../context/ServicesContext";
import { format, parseISO } from "date-fns";
import StyledTextField from "../StyledTextField";
import Dropdown from "../../Dropdown/Dropdown";
import { setOccurrenceUpdated } from "../../../features/occurrence/occurrenceSlice";
import { updateSelectFacilitie } from "../../../features/facilities/facilitiesSlice";

const style = {
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  backgroundImage:
    "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  p: 4,
};

const statusItems = [
  { name: "Pending" },
  { name: "Progress" },
  { name: "Validated" },
  { name: "Invalid" },
  { name: "Canceled" },
];

const intensities = [
  { name: "1 - Very Weak" },
  { name: "2 - Weak" },
  { name: "3 - Distinct" },
  { name: "4 - Strong " },
  { name: "5 - Very Strong" },
  { name: "6 - Extremely Strong" },
];

const justificationTypes = [
  { name: "Manual Justification" },
  { name: "Automatic Justification" },
];

function createData(
  id: number,
  name: string,
  intensity: number,
  justification_type: number,
  start_at: string,
  end_at: string,
  description: string,
  status: number,
  sources: any
) {
  return {
    id,
    name,
    intensity: intensities[intensity].name,
    justification_type: justificationTypes[justification_type].name,
    start_at,
    end_at,
    description,
    status: statusItems[status].name,
    sources,
  };
}

function Row(props: {
  row: ReturnType<typeof createData>;
  isOpen: boolean;
  setNewState: React.Dispatch<React.SetStateAction<boolean>>;
  setOccurrenceID: React.Dispatch<React.SetStateAction<any>>;
  onRowClick: () => void;
  fetchOccurrences: () => void;
  statusPos: number;
}) {
  const {
    row,
    isOpen,
    onRowClick,
    setNewState,
    setOccurrenceID,
    fetchOccurrences,
    statusPos,
  } = props;
  const dispatch = useAppDispatch();
  const { occurrenceUpdated } = useAppSelector((state) => state.occurrence);
  const { occurrenceService } = useServices();
  const [selectedStatus, setSelectedStatus] = useState<number>(statusPos);

  useEffect(() => {
    if (selectedStatus !== statusPos) {
      resolveOccurrence(row.id, selectedStatus, row.sources, statusPos);
    }
    // eslint-disable-next-line
  }, [selectedStatus]);

  function resolveOccurrence(
    id: number,
    status: any,
    sources?: any,
    oldStatus?: any
  ) {
    dispatch(setLoading(true));
    console.log("sources", sources);
    if (sources.length === 0 && status === 2) {
      setSelectedStatus(oldStatus);
      dispatch(setSnackBarOpen(true));
      dispatch(
        setSnackbarData({
          message: "Please select a source",
          severity: "error",
        })
      );
      dispatch(setLoading(false));
      return;
    } else {
      occurrenceService
        .resolveOccurrence(id, { status: status, sources: sources })
        .then((resp) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: "Occurrence resolved successfully",
              severity: "success",
            })
          );
          fetchOccurrences();
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onRowClick}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.intensity}</TableCell>
        <TableCell>{row.justification_type}</TableCell>
        <TableCell>
          <Dropdown
            className="status"
            label={""}
            options={statusItems}
            checkmarks={false}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box>
              <Box
                sx={{
                  margin: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Start at:{" "}
                  {format(parseISO(row.start_at), "d/MM/yyyy',' HH:mm")}
                </Typography>
                <Typography>
                  End at: {format(parseISO(row.end_at), "d/MM/yyyy',' HH:mm")}
                </Typography>
              </Box>
              <Box sx={{ margin: 1 }}>
                <StyledTextField
                  label="Description"
                  textarea={true}
                  disabled
                  value={row.description}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                }}
                variant="contained"
                onClick={() => {
                  setOccurrenceID(row.id);
                  setNewState(true);
                }}
              >
                Edit
              </Button>
              <Button
                sx={{
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="contained"
                color="error"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await occurrenceService
                    .deleteOccurrence(row.id)
                    .then((resp) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: "Occurrence deleted successfully",
                          severity: "success",
                        })
                      );
                      fetchOccurrences();
                      dispatch(setOccurrenceUpdated(!occurrenceUpdated));
                    })
                    .catch((err) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Delete
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface OccurrenceDisplayProps {
  id?: string;
  setOpen: (value: boolean) => void;
}

export default function OccurrenceDisplay({
  id,
  setOpen,
}: OccurrenceDisplayProps) {
  const dispatch = useAppDispatch();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const [newState, setNewState] = useState(false);
  const [occurrences, setOccurrences] = useState<any>();
  const [occurrenceID, setOccurrenceID] = useState<any>();
  const [selected, setSelected] = useState<number>(-1);
  const { occurrenceService } = useServices();
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  // Maintain the open state for each row
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (occurrences) {
      setRows(createRows(occurrences));
      setOpenRows({});
    }
  }, [occurrences]);

  async function fetchOccurrences() {
    dispatch(updateSelectFacilitie(selected));
    await occurrenceService
      .getOccurrence(facilitiesStore[selected].id)
      .then((resp) => {
        setOccurrences(resp.data);
        setRows(createRows(resp.data));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  function createRows(occurrences: any[]): any[] {
    return occurrences.map((occurrence) =>
      createData(
        occurrence.id,
        occurrence.name,
        occurrence.intensity,
        occurrence.justification_type,
        occurrence.start_at,
        occurrence.end_at,
        occurrence.description,
        occurrence.status,
        occurrence.sources
      )
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (id: number) => {
    let toggle = !openRows[id];
    setOpenRows((prevOpenRows) => {
      const newOpenRows = { ...prevOpenRows };
      Object.keys(newOpenRows).forEach((key: any) => {
        newOpenRows[key] = false;
      });
      newOpenRows[id] = toggle;
      return newOpenRows;
    });
  };
  return (
    <>
      {occurrences === undefined ? (
        <Box sx={style}>
          <Typography variant="h6" component="h2" mb={3} fontWeight="bold">
            Occurrence Panel
          </Typography>
          <FacilitySelection
            selected={selected}
            setSelected={setSelected}
            fetchOccurrences={fetchOccurrences}
            facilitiesStore={facilitiesStore}
            dispatch={dispatch}
          />
        </Box>
      ) : (
        <Card sx={{ minWidth: "960px" }}>
          <CardHeader
            sx={{
              paddingTop: "25px",
              span: { fontWeight: "bold" },
            }}
            title="Occurrence Panel"
            action={
              !newState ? (
                <Button
                  sx={{
                    marginTop: "5px",
                    fontSize: "10px",
                    borderRadius: "18px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  variant="contained"
                  onClick={() => {
                    setOccurrenceID(null);
                    setNewState(true);
                  }}
                >
                  New Occurrence
                </Button>
              ) : (
                <Button
                  sx={{
                    marginTop: "5px",
                    fontSize: "10px",
                    borderRadius: "18px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                  variant="contained"
                  onClick={() => setNewState(false)}
                >
                  Back
                </Button>
              )
            }
          />
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {!newState ? (
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Intensity</TableCell>
                      <TableCell align="left">Justification Type</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <Row
                            key={row.id}
                            row={row}
                            isOpen={openRows[row.id] || false}
                            onRowClick={() => handleRowClick(row.id)}
                            setNewState={setNewState}
                            setOccurrenceID={setOccurrenceID}
                            fetchOccurrences={fetchOccurrences}
                            statusPos={occurrences[index].status}
                          />
                        ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[3, 5, 10]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            ) : (
              <Grid container spacing={1}>
                <OccurrenceFields
                  setOpen={setOpen}
                  facility={selected}
                  occurrenceID={occurrenceID}
                  fetchOccurrences={fetchOccurrences}
                  setNewState={setNewState}
                />
              </Grid>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
}
