import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LatLngExpression } from "leaflet";

// Define a type for the slice state
interface Refineries {
    [index: string]: {
      name: string;
      pos: LatLngExpression;
      emissionSources: Array<{
        name: string;
        pos: LatLngExpression;
      }>;
    };
  }

// Define the initial state using that type
const initialState: Refineries = {
}

export const refinerySlice = createSlice({
  name: 'refinery',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Refineries>) => {
      return action.payload
    },
  },
})

export const { update } = refinerySlice.actions

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default refinerySlice.reducer