import { Box, Container } from "@mui/material";
import HeatmapLayer from "../HeatmapLayer";
import { useAppSelector } from "../../../app/hooks";
import styled from "styled-components";

export default function HeatmapLegend() {
  const { plume } = useAppSelector((state) => state.plume);
  const { selectedEmissionSources } = useAppSelector(
    (state) => state.emissionSource
  );
  let isOdour = selectedEmissionSources?.sources?.some(
    (source: any) => source.is_odour
  );

  const Text = styled.p`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: -12px;
    right: -30px;
    padding: 0px;

    &.odour {
      right: -96px;
    }
  `;

  return (
    <>
      {plume && (
        <Container
          sx={{
            position: "absolute",
            zIndex: 499,
            bottom: "-7px",
            left: "50%",
            transform: "translate(-50%, -100%)",
            margin: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "680px !important",
          }}
        >
          <div className="demo-wrapper">
            <div className="heatmap">
              <HeatmapLayer />
            </div>
            <Box className="legend-area">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span id="min"></span>
                <span id="blue"></span>
                <span id="green"></span>
                <span id="yellow"></span>
                <span id="max"></span>
              </Box>
              <img id="gradient" src="" alt="Heatmap Legend" />
              <Text className={isOdour ? "odour" : ""}>
                {isOdour ? "ou/m³ (odor unit)" : "g/m³"}
              </Text>
            </Box>
          </div>
        </Container>
      )}
    </>
  );
}
