import { IconButton, Slider as BaseSlider, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { updatePlumeNumber } from "../../../../features/plume/plumeSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { parse, format } from "date-fns";

interface SliderProps {
  lastStep: number;
  sliderValue: number;
  setSliderValue: Dispatch<SetStateAction<number>>;
  plumeDate: string;
  isAuto: boolean;
}

export default function Slider({
  lastStep,
  sliderValue,
  setSliderValue,
  plumeDate,
  isAuto,
}: SliderProps) {
  const dispatch = useAppDispatch();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying === false) {
      dispatch(updatePlumeNumber(sliderValue - 1));
    }
    // eslint-disable-next-line
  }, [sliderValue]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isPlaying || isAuto) {
      intervalId = setInterval(
        () => {
          setSliderValue((prevValue: number) => {
            const newValue = prevValue + 1;
            if (newValue <= lastStep) {
              dispatch(updatePlumeNumber(newValue - 1));
              return newValue;
            } else {
              clearInterval(intervalId as NodeJS.Timeout);
              setIsPlaying(false);
              return prevValue;
            }
          });
        },
        isAuto ? (lastStep === 2 ? 3600000 : 600000) : 1000
      ); // Update every 1000 milliseconds (1 second)
    } else {
      clearInterval(intervalId as unknown as NodeJS.Timeout);
    }

    return () => {
      clearInterval(intervalId as NodeJS.Timeout);
    };
    // eslint-disable-next-line
  }, [isPlaying, lastStep, isAuto]);

  const handlePlayClick = () => {
    if (isPlaying === true) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  function valuetext(value: number) {
    if (plumeDate) {
      let date = format(
        parse(plumeDate, "yyMMddHHmm", new Date()),
        "dd/MM/yy HH:mm"
      );
      return `${date}`;
    }
    return `${value}`;
  }

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={1}
        // sx={{ height: { xl: "40px", lg: "30px" } }}
        sx={{ height: { xs: "30px" } }}
      >
        <IconButton aria-label="SkipPrevious">
          <SkipPreviousIcon
            // sx={{ fontSize: { xl: "24px", lg: "18px" } }}
            sx={{ fontSize: { xs: "18px" } }}
            onClick={() => {
              setSliderValue(1);
            }}
          />
        </IconButton>
        <IconButton aria-label="FastRewind">
          <FastRewindIcon
            // sx={{ fontSize: { xl: "24px", lg: "18px" } }}
            sx={{ fontSize: { xs: "18px" } }}
            onClick={() => {
              setSliderValue(sliderValue === 1 ? lastStep : sliderValue - 1);
            }}
          />
        </IconButton>
        <IconButton aria-label="Start/Pause" onClick={handlePlayClick}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton aria-label="FastForward">
          <FastForwardIcon
            // sx={{ fontSize: { xl: "24px", lg: "18px" } }}
            sx={{ fontSize: { xs: "18px" } }}
            onClick={() => {
              setSliderValue(sliderValue === lastStep ? 1 : sliderValue + 1);
            }}
          />
        </IconButton>
        <IconButton aria-label="SkipNext">
          <SkipNextIcon
            // sx={{ fontSize: { xl: "24px", lg: "18px" } }}
            sx={{ fontSize: { xs: "18px" } }}
            onClick={() => {
              setSliderValue(lastStep);
            }}
          />
        </IconButton>
      </Stack>
      <BaseSlider
        size="small"
        value={sliderValue}
        step={1}
        marks
        min={1}
        max={lastStep}
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        valueLabelDisplay="auto"
        onChange={(event, newValue) => {
          setSliderValue(newValue as number);
        }}
      />
    </>
  );
}
