import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface EmissionSources {
  id: string;
  name: string;
}

interface DropdownProps {
  label: string;
  options?: EmissionSources[];
  groupedOptions?: any;
  checkmarkSelected: Array<string>;
  setCheckmarksSelected: Dispatch<SetStateAction<Array<string>>>;
}

export default function MultiOptDropdown({
  label,
  options,
  groupedOptions,
  checkmarkSelected,
  setCheckmarksSelected,
}: DropdownProps) {
  const checkmarkChangeHandler = (checkName: string) => {
    setCheckmarksSelected((prevSelected) => {
      if (prevSelected.includes(checkName)) {
        return prevSelected.filter((name) => name !== checkName);
      } else {
        return [...prevSelected, checkName];
      }
    });
  };

  const handleGroupOptionSelect = (groupName: string) => {
    // Retrieve options for the specified group
    const selectedGroupOptions = groupedOptions[groupName];

    // Extract names of selected options
    const selectedGroupOptionNames = selectedGroupOptions.map(
      (option: EmissionSources) => option.name
    );

    // Update checkmarksSelected array
    setCheckmarksSelected((prevSelected) => {
      // Concatenate previous selections with new selections
      const updatedSelected = [...prevSelected, ...selectedGroupOptionNames];

      // Filter out duplicates and options not present in the group
      const filteredSelected = updatedSelected.filter(
        (name, index) =>
          updatedSelected.indexOf(name) === index && // Keep only unique names
          (!prevSelected.includes(name) ||
            selectedGroupOptionNames.includes(name)) // Keep names not previously selected or from the current group
      );

      // If all selected options from the current group are already in the array, remove them
      if (filteredSelected.length === prevSelected.length) {
        return prevSelected.filter(
          (name) => !selectedGroupOptionNames.includes(name)
        );
      }

      return filteredSelected;
    });
  };

  return (
    <FormControl sx={{ display: "flex", pb: 2 }}>
      <>
        <InputLabel
          sx={{
            fontSize: { xs: "14px" },
            color: "rgba(255, 255, 255, 0.7)",
            "&.Mui-focused": {
              color: "rgba(255, 255, 255, 0.7)",
            },
          }}
          shrink
        >
          {label}
        </InputLabel>
        <Select
          sx={{
            width: "100%",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "& .MuiSelect-select": {
              padding: { xs: "5px 8px" },
              fontSize: "8px",
            },
          }}
          label={label}
          multiple
          notched
          value={checkmarkSelected}
          onChange={() => {}}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
        >
          {groupedOptions && Object.entries(groupedOptions).length > 0
            ? Object.entries(groupedOptions).map(
                ([groupName, groupOptions]: [string, any], index) => (
                  <>
                    <ListSubheader key={index}>
                      {groupName}
                      <Checkbox
                        onClick={() => handleGroupOptionSelect(groupName)}
                        checked={groupOptions.every(
                          (option: { name: string }) =>
                            checkmarkSelected.includes(option.name)
                        )}
                      />
                    </ListSubheader>
                    {groupOptions &&
                      groupOptions.map(
                        (value: EmissionSources, index: number) => (
                          <MenuItem key={index} value={value.name}>
                            <Checkbox
                              onChange={() =>
                                checkmarkChangeHandler(value.name)
                              }
                              checked={
                                checkmarkSelected.indexOf(value.name) > -1
                              }
                            />
                            <ListItemText primary={value.name} />
                          </MenuItem>
                        )
                      )}
                  </>
                )
              )
            : options?.map((value: EmissionSources, index: number) => (
                <MenuItem key={index} value={value.name}>
                  <Checkbox
                    checked={checkmarkSelected.indexOf(value.name) > -1}
                  />
                  <ListItemText primary={value.name} />
                </MenuItem>
              ))}
        </Select>
      </>
    </FormControl>
  );
}
