import { Client } from "./Client";

export class EmissionSourceService {
  constructor(private client: Client) {}

  async getEmissionSource(emissionSourceID: string) {
    return await this.client.getEmissionSource(emissionSourceID);
  }

  async postEmissionSource(emissionSource: any) {
    return await this.client.postEmissionSource(emissionSource);
  }

  async patchEmissionSource(emissionSourceID: string, emissionSource: any) {
    return await this.client.patchEmissionSource(
      emissionSourceID,
      emissionSource
    );
  }

  async deleteEmissionSource(emissionSourceID: string) {
    return await this.client.deleteEmissionSource(emissionSourceID);
  }
}
