import { IconButton, Tooltip } from "@mui/material";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import ForecastingModal from "./ForecastingModal";
import { useState } from "react";

function Forecasting() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Forecasting" placement="left">
        <IconButton
          size="large"
          aria-label="Forecasting"
          sx={{
            position: "absolute",
            top: "15px",
            right: "15px",
            zIndex: 500,
            bgcolor: "#0A161F",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgb(28, 33, 35)",
            },
          }}
          onClick={() => setOpen(true)}
        >
          <ThunderstormIcon />
        </IconButton>
      </Tooltip>
      <ForecastingModal open={open} setOpen={setOpen} />
    </>
  );
}

export default Forecasting;
