import { Box, Button } from "@mui/material";
import { id } from "date-fns/locale";
import { setEmissionSourceUpdated } from "../../../../features/emissionSource/emissionSourceSlice";
import { setLoading } from "../../../../features/loading/loadingSlice";
import { setOpen } from "../../../../features/plume/plumeSlice";
import {
  setSnackbarData,
  setSnackBarOpen,
} from "../../../../features/snackbar/snackbar";
import { set } from "date-fns";

interface ButtonsProps {
  step: number;
  setStep: any;
  emissionSource: any;
  setEmissionSource: any;
  emissionSourceService: any;
  dispatch: any;
  setOpen: any;
  id: any;
  emissionSourceUpdated: any;
}

function Buttons({
  step,
  setStep,
  emissionSource,
  setEmissionSource,
  emissionSourceService,
  dispatch,
  setOpen,
  id,
  emissionSourceUpdated,
}: ButtonsProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {id && (
        <Button
          sx={{
            maxHeight: "30px",
            fontSize: "10px",
            borderRadius: "18px",
            fontWeight: "700",
            marginBottom: "9px",
            marginRight: "auto",
            color: "rgba(0, 0, 0, 0.87)",
          }}
          variant="contained"
          color="error"
          onClick={async () => {
            dispatch(setLoading(true));
            await emissionSourceService
              .deleteEmissionSource(id)
              .then(() => {
                dispatch(
                  setSnackbarData({
                    message: "Emission Source Deleted successfully",
                    severity: "success",
                  })
                );
                dispatch(setEmissionSourceUpdated(!emissionSourceUpdated));
                dispatch(setLoading(false));
                setOpen(false);
              })
              .catch((err: { message: string }) => {
                dispatch(setSnackBarOpen(true));
                dispatch(
                  setSnackbarData({
                    message: err.message,
                    severity: "error",
                  })
                );
                dispatch(setLoading(false));
              });
          }}
        >
          Delete
        </Button>
      )}
      <Button
        sx={{
          maxHeight: "30px",
          fontSize: "10px",
          borderRadius: "18px",
          fontWeight: "700",
          marginBottom: "9px",
          color: "rgba(0, 0, 0, 0.87)",
        }}
        color="error"
        variant="contained"
        onClick={() => {
          setOpen(false);
        }}
      >
        Cancel
      </Button>
      {id ? (
        <>
          {step === 0 ? (
            <Button
              sx={{
                maxHeight: "30px",
                fontSize: "10px",
                borderRadius: "18px",
                fontWeight: "700",
                margin: "0 0 9px 9px",
              }}
              variant="contained"
              onClick={() => {
                setStep(1);
              }}
            >
              Next
            </Button>
          ) : (
            <>
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="contained"
                onClick={() => {
                  setStep(0);
                }}
              >
                Back
              </Button>
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                }}
                variant="contained"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await emissionSourceService
                    .patchEmissionSource(id, emissionSource)
                    .then(() => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: "Emission Source edited successfully",
                          severity: "success",
                        })
                      );
                      dispatch(setLoading(false));
                    })
                    .catch((err: { message: string }) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Edit
              </Button>
            </>
          )}
        </>
      ) : (
        <>
          {step === 1 ? (
            <>
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="contained"
                onClick={() => {
                  setStep(0);
                }}
              >
                Back
              </Button>
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                }}
                variant="contained"
                onClick={async () => {
                  dispatch(setLoading(true));
                  const filteredKeys = Object.keys(
                    emissionSource.typeData
                  ).filter((key: any) => {
                    if (
                      emissionSource.typeData[key] !== 0 &&
                      emissionSource.typeData[key].length !== 0
                    ) {
                      return key;
                    }
                  });
                  console.log(filteredKeys);
                  const filteredEmissionSource = {
                    ...emissionSource,
                    typeData: filteredKeys.reduce((obj: any, key: any) => {
                      obj[key] = emissionSource.typeData[key];
                      return obj;
                    }, {}),
                  };
                  console.log(filteredEmissionSource);
                  await emissionSourceService
                    .postEmissionSource(filteredEmissionSource)
                    .then(() => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: "Emission Source created successfully",
                          severity: "success",
                        })
                      );
                      dispatch(
                        setEmissionSourceUpdated(!emissionSourceUpdated)
                      );
                      dispatch(setLoading(false));
                      setOpen(false);
                    })
                    .catch((err: { message: string }) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              sx={{
                maxHeight: "30px",
                fontSize: "10px",
                borderRadius: "18px",
                fontWeight: "700",
                margin: "0 0 9px 9px",
              }}
              variant="contained"
              onClick={() => {
                setStep(1);
              }}
            >
              Next
            </Button>
          )}
        </>
      )}
    </Box>
  );
}

export default Buttons;
