import { Marker, Tooltip } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import Pin from "./Containers/Pin";
import { useAppSelector } from "../../app/hooks";

export default function Facilities() {
  const { facilitiesStore } = useAppSelector((state) => state.facilities);

  const iconMarkup = renderToStaticMarkup(<Pin />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  return (
    <>
      {facilitiesStore &&
        facilitiesStore.map((ref, index) => {
          return (
            <Marker
              key={index}
              icon={customMarkerIcon}
              position={[ref.lat, ref.lon]}
            >
              <Tooltip>{ref.name}</Tooltip>
            </Marker>
          );
        })}
    </>
  );
}
