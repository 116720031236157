import { Grid } from "@mui/material";
import StyledTextField from "../../StyledTextField";

interface PointProps {
  emissionSource: any;
  setEmissionSource: any;
}

function Volume({ emissionSource, setEmissionSource }: PointProps) {
  return (
    <>
      <Grid item xs={6}>
        <StyledTextField
          label="Vlemis - Volume Emission Rate (g/s)"
          type="number"
          value={emissionSource.typeData?.vlemis}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                vlemis: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Relhgt - Release Height (m)"
          type="number"
          value={emissionSource.typeData?.relhgt}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                relhgt: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Syinit - Initial Lateral Dimension (m)"
          type="number"
          value={emissionSource.typeData?.syinit}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                syinit: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Szinit - Initial Vertical Dimension (m)"
          type="number"
          value={emissionSource.typeData?.szinit}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                szinit: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
    </>
  );
}

export default Volume;
