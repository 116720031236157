import { Client } from "./Client";

export class OccurrenceService {
  constructor(private client: Client) {}

  async getOccurrence(facilityID: string) {
    return await this.client.getOccurrence(facilityID);
  }

  async postOccurrence(occurrence: any) {
    return await this.client.postOccurrence(occurrence);
  }

  async getOccurrenceByID(occurrenceID: number) {
    return await this.client.getOccurrenceByID(occurrenceID);
  }

  async patchOccurrence(occurrenceID: number, occurrence: any) {
    return await this.client.patchOccurrence(occurrenceID, occurrence);
  }

  async deleteOccurrence(occurrenceID: number) {
    return await this.client.deleteOccurrence(occurrenceID);
  }

  async resolveOccurrence(occurrenceID: number, occurrence: any) {
    return await this.client.resolveOccurrence(occurrenceID, occurrence);
  }
}
