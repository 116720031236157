import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { setViewPoints } from "../../../../../features/plume/plumeSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

interface OptionsProps {
  setPlumeStyle: (value: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (value: boolean) => void;
  activeIcons: boolean;
}

export function Options({
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
}: OptionsProps) {
  const dispatch = useAppDispatch();
  const [optionsVisible, setOptionsVisible] = useState(true);
  const { pointView } = useAppSelector((state) => state.plume);

  return (
    <Card
      sx={{
        backgroundColor: "#282c2e",
      }}
    >
      <CardHeader
        sx={{
          cursor: "pointer",
          color: "white",
          backgroundColor: "#0A161F",
          padding: "5px 15px 5px 15px",
          span: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
        title="Options"
        action={optionsVisible ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => setOptionsVisible(!optionsVisible)}
      />
      {optionsVisible && (
        <CardContent
          sx={{ padding: "5px 15px", "&:last-child": { paddingBottom: "5px" } }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  value={plumeStyle}
                  onChange={() => setPlumeStyle(!plumeStyle)}
                />
              }
              label={
                <Typography
                  sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Heatmap
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => dispatch(setViewPoints(!pointView))}
                />
              }
              label={
                <Typography
                  sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Points
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox onChange={() => setActiveIcons(!activeIcons)} />
              }
              label={
                <Typography
                  sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                >
                  Icons
                </Typography>
              }
            />
          </Box>
        </CardContent>
      )}
    </Card>
  );
}
