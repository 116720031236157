import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const plumeNumber: number = -1;

const plume: any = undefined;

const open: boolean = false;

const pointView: boolean = false;

const storedData: {
  facilityID: string;
  facilityPos: number;
  startDate: string;
  endDate: string;
  emissionSources: any;
  time: number;
} = {
  facilityID: "",
  facilityPos: -1,
  startDate: "",
  endDate: "",
  emissionSources: [],
  time: 0,
};

export const plumeNumberSlice = createSlice({
  name: "plume",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: {
    plume: plume,
    plumeNumber: plumeNumber,
    open: open,
    storedData: storedData,
    pointView: pointView,
  },
  reducers: {
    updatePlume: (state, action: PayloadAction<any>) => {
      state.plume = action.payload;
    },
    updatePlumeNumber: (state, action: PayloadAction<number>) => {
      state.plumeNumber = action.payload;
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setStoredData: (state, action: PayloadAction<any>) => {
      state.storedData = action.payload;
    },
    setViewPoints: (state, action: PayloadAction<boolean>) => {
      state.pointView = action.payload;
    },
  },
});

export const {
  updatePlume,
  updatePlumeNumber,
  setOpen,
  setStoredData,
  setViewPoints,
} = plumeNumberSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default plumeNumberSlice.reducer;
