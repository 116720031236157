import { Key, useEffect, useState } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useServices } from "../../context/ServicesContext";
import { setLoading } from "../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../features/snackbar/snackbar";
import Pin from "./Containers/Pin";

export default function Occurrences() {
  const dispatch = useAppDispatch();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { occurrenceUpdated } = useAppSelector((state) => state.occurrence);
  const [occurrences, setOccurrences] = useState<any>();
  const { occurrenceService } = useServices();

  useEffect(() => {
    fetchOccurrence();
  }, [facilitiesStore, occurrenceUpdated]);

  async function fetchOccurrence() {
    if (facilitiesStore && facilitiesStore[0] && facilitiesStore[0].id) {
      dispatch(setLoading(true));
      await occurrenceService
        .getOccurrence(facilitiesStore[0].id)
        .then((resp) => {
          setOccurrences(resp.data);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  const iconMarkup = renderToStaticMarkup(<Pin />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  return (
    <>
      {occurrences &&
        occurrences.length > 0 &&
        occurrences.map(
          (
            ref: { lat: number; lon: number; id: string; name: string },
            index: Key | null | undefined
          ) => {
            return (
              <>
                <Marker
                  key={index}
                  icon={customMarkerIcon}
                  position={[ref.lat, ref.lon]}
                >
                  <Tooltip>{ref.name}</Tooltip>
                </Marker>
              </>
            );
          }
        )}
    </>
  );
}
