import { Grid } from "@mui/material";
import StyledTextField from "../../StyledTextField";

interface PointProps {
  emissionSource: any;
  setEmissionSource: any;
}

function Line({ emissionSource, setEmissionSource }: PointProps) {
  return (
    <>
      <Grid item xs={6}>
        <StyledTextField
          label="Lnemis - Line Emission Rate (g/s-m2)"
          type="number"
          value={emissionSource.typeData?.lnemis}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                lnemis: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Relhgt - Average Release Height (m)"
          type="number"
          value={emissionSource.typeData?.relhgt}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                relhgt: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Width - Width of the source (min 1 m)"
          type="number"
          value={emissionSource.typeData?.width}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                width: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          label="Szinit - Initial Vertical Dimension (m)"
          type="number"
          value={emissionSource.typeData?.szinit}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                szinit: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
    </>
  );
}

export default Line;
