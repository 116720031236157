import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PlumeService } from "./service/PlumeService";
import { ServicesProvider } from "./context/ServicesContext";
import { Client } from "./service/Client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { FacilitiesService } from "./service/FacilitiesService";
import { ForecastService } from "./service/ForecastService";
import { EmissionSourceService } from "./service/EmissionSourceService";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { OccurrenceService } from "./service/OccurrenceService";
import { PlacesService } from "./service/PlacesService";
import App from "./pages/App";
import { LoginService } from "./service/LoginService";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#75F94D",
    },
  },
});

function getToken() {
  let access_token = sessionStorage.getItem("access_token");
  if (access_token) {
    return access_token;
  }
}

const client = new Client(
  "https://admin.airesflow.com.br/api/v1/",
  getToken
);

const plumeService = new PlumeService(client);
const facilitiesService = new FacilitiesService(client);
const forecastService = new ForecastService(client);
const emissionSourceService = new EmissionSourceService(client);
const occurrenceService = new OccurrenceService(client);
const placesService = new PlacesService(client);
const loginService = new LoginService(client);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={darkTheme}>
      <ServicesProvider
        plumeService={plumeService}
        facilitiesService={facilitiesService}
        forecastService={forecastService}
        emissionSourceService={emissionSourceService}
        occurrenceService={occurrenceService}
        placesService={placesService}
        loginService={loginService}
      >
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </LocalizationProvider>
      </ServicesProvider>
    </ThemeProvider>
  </Provider>
);
