import { Client } from "./Client";

export class FacilitiesService {
  constructor(private client: Client) {}

  async fetchFacilities() {
    return await this.client.fetchFacilities();
  }

  async fetchEmissionSource(facilityID: string) {
    return await this.client.fetchEmissionSource(facilityID);
  }

  async fetchStations(facilityID: string) {
    return await this.client.fetchStations(facilityID);
  }

  async postFacility(facility: any) {
    return await this.client.postFacility(facility);
  }

  async patchFacility(facilityID: string, facility: any) {
    return await this.client.patchFacility(facilityID, facility);
  }

  async deleteFacility(facilityID: string) {
    return await this.client.deleteFacility(facilityID);
  }
}
