import Container from "@mui/material/Container";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import MenuNav from "./Containers/MenuNav/MenuNav";
import MenuBody from "./Containers/MenuBody/MenuBody";
import { useAppSelector } from "../../app/hooks";
import { setOpen } from "../../features/plume/plumeSlice";

interface FloatMenuProps {
  setPlumeStyle: Dispatch<SetStateAction<boolean>>;
  plumeStyle: boolean;
  setActiveIcons: Dispatch<SetStateAction<boolean>>;
  activeIcons: boolean;
}

export default function FloatMenu({
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
}: FloatMenuProps) {
  const map = useMap();
  const [plumeDate, setPlumeDate] = useState<string | undefined>();
  // const [open, setOpen] = useState(false);
  const { open } = useAppSelector((state) => state.plume);
  const { facilitiesStore, selectFacilitie } = useAppSelector(
    (state) => state.facilities
  );

  useEffect(() => {
    if (facilitiesStore && selectFacilitie !== -1) {
      map.flyTo(
        [
          facilitiesStore[selectFacilitie].lat,
          facilitiesStore[selectFacilitie].lon,
        ],
        14
      );
    }
  }, [selectFacilitie, map]);

  return (
    <>
      <Container
        onMouseOver={() => {
          map.dragging.disable();
          map.scrollWheelZoom.disable();
        }}
        onMouseOut={() => {
          map.dragging.enable();
          map.scrollWheelZoom.enable();
        }}
        maxWidth={false}
        sx={{
          maxWidth: "320px",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: "500",
          bgcolor: "rgb(30, 36, 52)",
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          borderBottomLeftRadius: open ? "20px" : "0px",
          borderBottomRightRadius: open ? "20px" : "0px",
        }}
      >
        <MenuNav
          plumeDate={plumeDate !== undefined ? plumeDate : ""}
          open={open}
          setOpen={setOpen}
        />
        <MenuBody
          open={open}
          setPlumeStyle={setPlumeStyle}
          plumeStyle={plumeStyle}
          setPlumeDate={setPlumeDate}
          setActiveIcons={setActiveIcons}
          activeIcons={activeIcons}
        />
      </Container>
    </>
  );
}
