import { Box, Grid, Typography } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SpeedIcon from "@mui/icons-material/Speed";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import CloudIcon from "@mui/icons-material/Cloud";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import AirIcon from "@mui/icons-material/Air";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { styled } from "@mui/material/styles";

const Item = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
  fontSize: "9px",
  lineHeight: "10px",
  padding: "4px 4px",
}));

interface WeatherProps {
  plumeInfo: any;
}

export default function Weather({ plumeInfo }: WeatherProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        {plumeInfo ? (
          <>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <WbSunnyIcon sx={{ fontSize: { xl: "65px", lg: "45px" } }} /> */}
              <WbSunnyIcon sx={{ fontSize: { xs: "45px" } }} />
            </Grid>
            <Grid item container xs={7}>
              <Grid item xs={6} sx={{ border: "1px solid", borderTop: "none" }}>
                <Item>
                  <ThermostatIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />{" "}
                  {plumeInfo.temp} °C
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  border: "1px solid",
                  borderTop: "none",
                  borderRight: "none",
                }}
              >
                <Item>
                  <UmbrellaIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />
                  {plumeInfo.precipitation} mm
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid" }}>
                <Item>
                  <SpeedIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />
                  {plumeInfo.pressure} hpa
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ border: "1px solid", borderRight: "none" }}
              >
                <Item>
                  <CloudIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />{" "}
                  {plumeInfo.cloudCover}%
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid" }}>
                <Item>
                  <NorthEastIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />
                  {plumeInfo.windDirection}
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ border: "1px solid", borderRight: "none" }}
              >
                <Item>
                  <AirIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />
                  {plumeInfo.windSpeed.toFixed(4)} m/s
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ border: "1px solid", borderBottom: "none" }}
              >
                <Item>
                  <WaterDropIcon
                    // sx={{ fontSize: { xl: "20px", lg: "15px" } }}
                    sx={{ fontSize: { xs: "15px" } }}
                  />
                  {plumeInfo.humidity}%
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  border: "1px solid",
                  borderBottom: "none",
                  borderRight: "none",
                }}
              >
                <Item>Complaint Risk Not Applicable</Item>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              m={2}
              // sx={{ width: "100%", height: { xl: "118px", lg: "103px" } }}
              sx={{
                width: "100%",
                height: { xs: "103px" },
                textAlign: "center",
              }}
            >
              <Typography variant="h4">Select facility…</Typography>
            </Grid>
            <></>
          </>
        )}
      </Grid>
    </Box>
  );
}
