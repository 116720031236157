import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FacilityDisplay from "./Containers/FacilityDisplay";
import EmissionSourceDisplay from "./Containers/EmissionSourceDisplay";
import OccurrenceDisplay from "./Containers/OccurrenceDisplay";
import PlaceDisplay from "./Containers/PlaceDisplay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

interface CrudModalProps {
  id?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type: string;
}

export default function CrudModal({ id, open, setOpen, type }: CrudModalProps) {
  const handleClose = () => setOpen(false);

  const modalContent = () => {
    switch (type) {
      case "Facility":
        return <FacilityDisplay setOpen={setOpen} />;
      case "Place":
        return <PlaceDisplay id={id} setOpen={setOpen} />;
      case "EmissionSource":
        return <EmissionSourceDisplay id={id} setOpen={setOpen} />;
      case "Occurrence":
        return <OccurrenceDisplay id={id} setOpen={setOpen} />;
      default:
        console.error(`Unhandled type: ${type}`);
        return null;
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} maxWidth={{ xl: "60%" }}>
        {modalContent()}
      </Box>
    </Modal>
  );
}
