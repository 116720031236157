import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import CloudIcon from "@mui/icons-material/Cloud";
import AirIcon from "@mui/icons-material/Air";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction } from "react";
import { format, parse } from "date-fns";
import { useAppDispatch } from "../../../app/hooks";
import { setLoading } from "../../../features/loading/loadingSlice";
import { ForecastData } from "../../../interfaces/Forecast";

interface ForecastingCardProps {
  forecastData: ForecastData;
  dateKey: string;
  fetchForecastData: (period: string) => void;
  setForecastDay: Dispatch<SetStateAction<string | undefined>>;
}

const Item = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 10px;
  padding: 4px 4px;
  min-height: 37px;
`;

const ForecastItem = ({
  icon,
  data,
  label,
}: {
  icon: JSX.Element;
  data: number | string;
  label: string;
}) => (
  <Grid item xs={4} sx={{ borderTop: "1px solid" }}>
    <Item>
      {icon}
      {data} {label}
    </Item>
  </Grid>
);

function ForecastingCard({
  forecastData,
  dateKey,
  fetchForecastData,
  setForecastDay,
}: ForecastingCardProps) {
  const dispatch = useAppDispatch();

  return (
    <Card
      sx={{
        margin: 2,
        backgroundColor: "transparent",
        backgroundImage: "none",
        border: "1px solid",
      }}
    >
      <CardHeader
        sx={{
          color: "white",
          padding: "5px 15px 5px 15px",
          span: { fontSize: "12px" },
        }}
        title={format(
          parse(dateKey, "yyyyMMddHH", new Date()),
          "cccc MMMM dd, yyyy"
        )}
        titleTypographyProps={{ align: "center" }}
      />
      <CardContent
        sx={{
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:last-child": { paddingBottom: "10px" },
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography p={1} sx={{ fontSize: "30px", fontWeight: 700 }}>
              {forecastData.temp}°C
            </Typography>
          </Grid>
          <Grid item container xs={4} md={2} lg={7}>
            <ForecastItem
              icon={<AirIcon fontSize="small" />}
              data={forecastData.ws}
              label="m/s"
            />
            <ForecastItem
              icon={<UmbrellaIcon fontSize="small" />}
              data={forecastData.pamt}
              label="mm"
            />
            <ForecastItem
              icon={<CloudIcon fontSize="small" />}
              data={forecastData.ccvr}
              label="%"
            />
            <Grid item xs={12} sx={{ borderTop: "1px solid" }}>
              <Item>Wind Direction: {forecastData.wd}</Item>
            </Grid>
            <Grid item xs={12} sx={{ borderTop: "1px solid" }}>
              <Item>Barometric Pressure: {forecastData.pres} hpa</Item>
            </Grid>
            <Grid item xs={12} sx={{ borderTop: "1px solid" }}>
              <Item>Cloud coverage: {forecastData.ccvr}%</Item>
            </Grid>
            <Grid item xs={12} sx={{ borderTop: "1px solid" }}>
              <Item>Relative Humidity: {forecastData.rh}%</Item>
            </Grid>
          </Grid>
          <Button
            sx={{
              maxHeight: "30px",
              fontSize: "9px",
              borderRadius: "18px",
              fontWeight: "700",
            }}
            variant="contained"
            onClick={() => {
              dispatch(setLoading(true));
              fetchForecastData("hours");
              setForecastDay(dateKey);
            }}
          >
            Hourly Forecast
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ForecastingCard;
