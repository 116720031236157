import styled from "styled-components";

interface IconProps {
  type?: string;
  color?: string;
  width?: string;
  height?: string;
}

const Icon = styled.svg`
  font-size: 20px !important;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;

const EmissionSourceIcon = ({ type, color, width, height }: IconProps) => (
  <Icon
    style={{
      width: width ? width : "20px",
      height: height ? height : "20px",
      transform:
        type === "button" ? "translate(-50%, -50%)" : "translate(-50%, -100%)",
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 60 90"
      fill={color ? color : "#478ECC"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.973 37.7075C12.1812 36.7984 12.99 36.1538 13.9226 36.1538H24.6575H35.3925C36.3251 36.1538 37.1339 36.7984 37.342 37.7075L48.7549 87.5536C49.0417 88.806 48.0901 90 46.8054 90H2.50968C1.22492 90 0.273387 88.806 0.560129 87.5536L11.973 37.7075Z"
        fill={color ? color : "#478ECC"}
      />
      <rect
        y="27.6923"
        width="49.3151"
        height="7.69231"
        rx="2"
        fill={color ? color : "#478ECC"}
      />
      <ellipse
        cx="23.0137"
        cy="19.2308"
        rx="8.21918"
        ry="7.69231"
        fill={color ? color : "#478ECC"}
      />
      <ellipse
        cx="31.2329"
        cy="7.69231"
        rx="8.21918"
        ry="7.69231"
        fill={color ? color : "#478ECC"}
      />
      <ellipse
        cx="51.7808"
        cy="19.2308"
        rx="8.21918"
        ry="7.69231"
        fill={color ? color : "#478ECC"}
      />
      <ellipse
        cx="39.4521"
        cy="15.3846"
        rx="12.3288"
        ry="11.5385"
        fill={color ? color : "#478ECC"}
      />
    </svg>
  </Icon>
);

export default EmissionSourceIcon;
