import { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useServices } from "../../context/ServicesContext";
import { setLoading } from "../../features/loading/loadingSlice";
import FacilitySelection from "./Containers/FacilitySelection";
import Header from "./Containers/Header";
import ForecastDisplay from "./Containers/ForecastDisplay";
import { Forecast } from "../../interfaces/Forecast";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../features/snackbar/snackbar";
import { setStoredData } from "../../features/plume/plumeSlice";
import { updateSelectFacilitie } from "../../features/facilities/facilitiesSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  backgroundColor: "rgb(18 18 18)",
  backgroundImage:
    "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  boxShadow: 24,
};

interface ForecastingModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ForecastingModal({
  open,
  setOpen,
}: ForecastingModalProps) {
  const dispatch = useAppDispatch();
  const handleClose = () => setOpen(false);
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { forecastService } = useServices();
  const [selected, setSelected] = useState<number>(-1);
  const [forecast, setForecast] = useState<Forecast | undefined>();
  const [keys, setKeys] = useState<string[] | undefined>();
  const [stage, setStage] = useState<number>(0);
  const [forecastDay, setForecastDay] = useState<string>();
  const { isLoading } = useAppSelector((state) => state.loading);

  async function fetchForecast(period: string) {
    dispatch(updateSelectFacilitie(selected));
    dispatch(
      setStoredData({
        facilityID: facilitiesStore[selected].id,
        facilityPos: selected,
      })
    );
    await forecastService
      .fetchForecast(facilitiesStore[selected].id, period)
      .then((data) => {
        setForecast(data.result);
        setKeys(Object.keys(data.result));
        setStage(period === "days" ? 1 : 2);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={style}
        width={stage === 0 ? "400px" : "77%"}
        maxWidth={"950px"}
        p={{ xs: stage === 0 ? 4 : 2 }}
      >
        <Header
          stage={stage}
          selected={selected}
          facilitiesStore={facilitiesStore}
          setStage={setStage}
          fetchForecast={fetchForecast}
          dispatch={dispatch}
          setLoading={setLoading}
        />
        {stage === 0 ? (
          <FacilitySelection
            selected={selected}
            setSelected={setSelected}
            fetchForecast={fetchForecast}
            facilitiesStore={facilitiesStore}
            dispatch={dispatch}
          />
        ) : (
          <ForecastDisplay
            stage={stage}
            isLoading={isLoading}
            forecastDay={forecastDay}
            forecast={forecast}
            keys={keys}
            fetchForecast={fetchForecast}
            setForecastDay={setForecastDay}
            setOpen={setOpen}
          />
        )}
      </Box>
    </Modal>
  );
}
