import { Circle, Polygon, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useAppSelector } from "../../app/hooks";
import * as densityClustering from "density-clustering";
import hull from "hull.js";
import Legend from "./Containers/Legend";
import { LatLngExpression } from "leaflet";

interface UTMPoint {
  lat: number;
  lon: number;
  c: number;
}

interface IntensityRange {
  start: string;
  end: string;
}

// Find the avg value for the c
function calculateAverage(points: UTMPoint[]): number {
  // Extract the values you want to average
  let values = points.map((item) => item.c);

  // Calculate the sum of the values
  let sum = values.reduce((acc, curr) => acc + curr, 0);

  // Calculate the average
  let average = sum / points.length;

  return average;
}

// Function to compare average values
function filterIntensityRanges(intensityRanges: UTMPoint[][]): UTMPoint[][] {
  if (intensityRanges.length === 0) {
    return [];
  }

  // Ensure you have exactly 8 intensity ranges
  if (intensityRanges.length > 8) {
    intensityRanges = intensityRanges.slice(0, 8);
  } else if (intensityRanges.length < 8) {
    const lastRange = intensityRanges[intensityRanges.length - 1];
    const remainingRanges = Array(8 - intensityRanges.length).fill(lastRange);
    intensityRanges = intensityRanges.concat(remainingRanges);
  }

  return intensityRanges;
}

export default function LinePlumeLayer() {
  const { plume, plumeNumber, pointView } = useAppSelector(
    (state) => state.plume
  );
  const { selectedEmissionSources } = useAppSelector(
    (state) => state.emissionSource
  );
  let isOdour = selectedEmissionSources?.sources?.some(
    (source: any) => source.is_odour
  );

  var intensityRangeValues: IntensityRange[] = [];

  if (plume && plumeNumber !== -1) {
    const points = plume.result[plumeNumber].plume as UTMPoint[];
    const min = plume.result[plumeNumber].min;
    const max = plume.result[plumeNumber].max;

    const minIntensity = min;
    const maxIntensity = max;

    if (points) {
      const logMin = Math.log(minIntensity);
      const logMax = Math.log(maxIntensity);

      // Create an array to store the intensity ranges
      const intensityRanges: UTMPoint[][] = [];

      const ranges = [
        { start: 0, end: 0.25 },
        { start: 0.25, end: 0.3 },
        { start: 0.3, end: 0.4 },
        { start: 0.4, end: 0.55 },
        { start: 0.55, end: 0.6 },
        { start: 0.6, end: 0.7 },
        { start: 0.7, end: 0.85 },
        { start: 0.85, end: 1.0 },
      ];

      ranges.forEach((range) => {
        const start = Math.exp(logMin + range.start * (logMax - logMin));
        const end = Math.exp(logMin + range.end * (logMax - logMin));
        // Filter points for the current range
        const rangePoints = points.filter(
          (point: { c: number }) => point.c >= start && point.c < end
        );

        // Adjust the threshold for minimum points
        if (rangePoints.length >= 3) {
          intensityRanges.push(rangePoints);
        }
      });

      // Sort each array by intensity value
      intensityRanges.forEach((range) => {
        range.sort((a, b) => a.c - b.c);
      });

      // Filter intensity ranges based on the average values
      let filteredIntensityRanges = filterIntensityRanges(intensityRanges);

      // Define the color gradient
      const gradientColors = [
        "#140f69",
        "#104c54",
        "#0b8f2c",
        "#25dd1b",
        "#5ccc06",
        "#e6c224",
        "#ec761e",
        "#b8392a",
      ];
      // const gradientColors = [
      //   "#0c0887",
      //   "#4b03a1",
      //   "#7d03a8",
      //   "#a82296",
      //   "#cb4679",
      //   "#e56b5d",
      //   "#f89441",
      //   "#f0f921",
      // ];

      var concaveHullLatLng: LatLngExpression[] = [];

      // console.log(filteredIntensityRanges);

      // Find the border points for each intensity range and render polygons
      const polygons = filteredIntensityRanges.map((range, index) => {
        // Use density clustering to split the points into clusters
        const dbscan = new densityClustering.DBSCAN();
        const clusters = dbscan.run(
          range.map((point) => [point.lat, point.lon]),
          0.043, // epsilon
          3 // minPoints
        );

        // Use the hull function for each cluster
        const clusterHulls = clusters.map((cluster: any[]) => {
          const clusterPoints = cluster.map(
            (pointIndex: string | number) => range[pointIndex as number]
          );
          return hull(clusterPoints, 0.083, [".lat", ".lon"]);
        });

        // Combine the hulls into a multipolygon
        const multipolygon: LatLngExpression[][][] = clusterHulls.map(
          (hullPoints: any[]) =>
            hullPoints.map((point: { lat: any; lon: any }) => [
              point.lat,
              point.lon,
            ])
        );

        intensityRangeValues.push({
          start: range[0].c.toExponential(),
          end: range[range.length - 1].c.toExponential(),
        });

        // Calculate color based on gradient
        const color = gradientColors[index];

        return (
          <>
            {multipolygon.map((polygon, polygonIndex) => (
              <>
                <Polygon
                  key={`${index}-${polygonIndex}`}
                  positions={polygon}
                  pathOptions={{
                    color: color,
                    fillColor: color,
                    fillOpacity: 0.5,
                    weight: 2,
                  }}
                >
                  <Popup>
                    Average: {calculateAverage(range).toFixed(5)}{" "}
                    {isOdour ? "ou/m³ (odor unit)" : "g/m³"}
                  </Popup>
                </Polygon>
              </>
            ))}
          </>
        );
      });

      return (
        <>
          {pointView
            ? intensityRanges.map((range, index) =>
                range.map((point: { lat: number; lon: number; c: number }) => (
                  <Circle
                    key={index}
                    center={[point.lat, point.lon]}
                    pathOptions={{
                      color: gradientColors[index],
                      fillColor: gradientColors[index],
                      fillOpacity: 1,
                    }}
                    radius={100}
                  >
                    <Popup>
                      Concentration: {point.c}{" "}
                      {isOdour ? "ou/m³ (odor unit)" : "g/m³"}
                    </Popup>
                  </Circle>
                ))
              )
            : null}
          {!pointView && polygons}
          <Legend
            isOdour={isOdour}
            concaveHullLatLng={concaveHullLatLng}
            minIntensity={minIntensity}
            maxIntensity={maxIntensity}
          />
        </>
      );
    }
  }

  return null;
}
