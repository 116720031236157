import { useAppDispatch } from "../../../app/hooks";
import { useServices } from "../../../context/ServicesContext";
import { setLoading } from "../../../features/loading/loadingSlice";
import { Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import StyledTextField from "../StyledTextField";
import { useEffect, useState } from "react";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../features/snackbar/snackbar";

interface Facility {
  name: string;
  lat: number;
  lon: number;
  zone: number;
  location: {
    lat: number;
    lon: number;
  };
  created_by: number;
  updated_by: number;
}

interface FacilityDisplayProps {
  setOpen: (value: boolean) => void;
}

function FacilityDisplay({ setOpen }: FacilityDisplayProps) {
  const dispatch = useAppDispatch();
  const { facilitiesService } = useServices();
  const [facility, setFacility] = useState<Facility>({} as Facility);

  useEffect(() => {
    setFacility({
      ...facility,
      location: { lat: facility.lat, lon: facility.lon },
      created_by: 1,
      updated_by: 1,
    });
  }, [facility.lat, facility.lon]);

  return (
    <Card>
      <CardHeader title="New Facility" sx={{ span: { fontWeight: "bold" } }} />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <StyledTextField
          label="Facility Name"
          value={facility.name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setFacility({ ...facility, name: e.target.value });
          }}
        />
        <StyledTextField
          label="Latitude"
          type="number"
          value={facility.lat}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setFacility({ ...facility, lat: Number(e.target.value) });
          }}
        />
        <StyledTextField
          label="Longitude"
          type="number"
          value={facility.lon}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setFacility({ ...facility, lon: Number(e.target.value) });
          }}
        />
        <StyledTextField
          label="Timezone"
          value={facility.zone}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setFacility({ ...facility, zone: Number(e.target.value) });
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              maxHeight: "30px",
              fontSize: "10px",
              borderRadius: "18px",
              fontWeight: "700",
              marginBottom: "9px",
              color: "rgba(0, 0, 0, 0.87)",
            }}
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              maxHeight: "30px",
              fontSize: "10px",
              borderRadius: "18px",
              fontWeight: "700",
              margin: "0 0 9px 9px",
            }}
            variant="contained"
            onClick={async () => {
              dispatch(setLoading(true));
              await facilitiesService
                .postFacility(facility)
                .then((data) => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({
                      message: "Facility Created Success",
                      severity: "success",
                    })
                  );
                  dispatch(setLoading(false));
                  setOpen(false);
                })
                .catch((err) => {
                  dispatch(setSnackBarOpen(true));
                  dispatch(
                    setSnackbarData({ message: err.message, severity: "error" })
                  );
                  dispatch(setLoading(false));
                });
            }}
          >
            Save
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default FacilityDisplay;
