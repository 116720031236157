import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  let access_token = sessionStorage.getItem("access_token");
  let refresh_token = sessionStorage.getItem("refresh_token");

  if (
    access_token === "" ||
    access_token === null ||
    refresh_token === "" ||
    refresh_token === null
  ) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};
