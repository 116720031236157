import styled from "styled-components";
import StyledTextField from "../../StyledTextField";
import { Grid } from "@mui/material";
import { ChangeEvent } from "react";

interface PointProps {
  points: string;
  setPoints: any;
  type: string;
  error: boolean;
}

const PointTextField = styled(StyledTextField)`
  width: 100%;
  /* max-width: 256px; */
  &:nth-child(odd) {
    margin: 0px 8px 0px 0px !important;
  }
`;

function AreaLinePoints({
  points,
  setPoints,
  error,
  type,
}: PointProps) {

  const handleAddPoint = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const points_fix = e.target.value.replaceAll(/[^0-9\s\n-.]/g, "")
    setPoints(points_fix);
  };

  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "8px",
            paddingTop: "6px",
            width: "100%",
            maxHeight: "150px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px"
            }}
          >
            <StyledTextField
              label={type === "LINE" ? "Linha" : "Area"}
              placeholder={`ex:\n-27.7157 -53.7373\n-24.7027 -53.7414\n-24.7165 -53.7578`}
              rows={4}
              value={points}
              textarea={true}
              error={error}
              helperText={error ? "Campo inválido!" : null}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                handleAddPoint(e)
              }
            />
          </div>

        </div>
      </Grid>
    </>
  );
}

export default AreaLinePoints;
