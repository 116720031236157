import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

interface SettingsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPlumeStyle: (checked: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (checked: boolean) => void;
  activeIcons: boolean;
  setIcons: ({
    facility,
    emissionSource,
    occurrence,
    places,
  }: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  }) => void;
  icons: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  };
}

export default function SettingsModal({
  open,
  setOpen,
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
  setIcons,
  icons,
}: SettingsModalProps) {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Card
          sx={{
            backgroundColor: "#282c2e",
            borderRadius: "0px",
          }}
        >
          <CardHeader
            sx={{
              color: "white",
              backgroundColor: "#0A161F",
              padding: "5px 15px 5px 15px",
              span: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            }}
            title="Options"
          />
          <CardContent
            sx={{
              padding: "5px 15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "rgba(255, 255, 255, 0.7)",
                }}
              >
                Icons
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeIcons}
                    onChange={() => setActiveIcons(!activeIcons)}
                  />
                }
                label={
                  <Typography
                    sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                  >
                    Hide All Icons
                  </Typography>
                }
              />
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.facility}
                        onChange={() =>
                          setIcons({ ...icons, facility: !icons.facility })
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Facility Icons
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.emissionSource}
                        onChange={() =>
                          setIcons({
                            ...icons,
                            emissionSource: !icons.emissionSource,
                          })
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Emission Sources Icons
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.occurrence}
                        onChange={() =>
                          setIcons({ ...icons, occurrence: !icons.occurrence })
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Occurrences Icons
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.places}
                        onChange={() =>
                          setIcons({ ...icons, places: !icons.places })
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: 12, color: "rgba(255, 255, 255, 0.7)" }}
                      >
                        Places Icons
                      </Typography>
                    }
                  />
                  <Divider
                    sx={{ width: "100%", color: "rgba(255, 255, 255, 0.7)" }}
                  />
                  <Link
                    to="/login"
                    onClick={() => {
                      sessionStorage.removeItem("access_token");
                      sessionStorage.removeItem("refresh_token");
                    }}
                  >
                    <Button style={{ marginTop: "5px", textTransform: "none" }}>
                      Logout
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
