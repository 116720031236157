import styled from "styled-components";
import EmissionSourceIcon from "../../../assets/icons/EmissionSource";
import Marker from "../../../assets/icons/Marker";

export default function Pin() {
  const Container = styled.div`
    position: relative;
    width: 30px;
  `;

  return (
    <Container>
      <Marker fill="white" />
      <EmissionSourceIcon />
    </Container>
  );
}
