import { useEffect, useState } from "react";
import { MapContainer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import FloatMenuProps from "../components/FloatMenu/FloatMenu";
import FacilitiesIcon from "../components/Facilities/Facilities";
import EmissionSource from "../components/EmissionSource/EmissionSource";
import LayersControl from "../components/LayersControl/LayersControl";
import LinePlumeLayer from "../components/LinePlumeLayer/LinePlumeLayer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Loader from "../components/Loader/Loader";
import { useServices } from "../context/ServicesContext";
import { updateFacilities } from "../features/facilities/facilitiesSlice";
import HeatmapLegend from "../components/HeatmapLayer/Containers/HeatmapLegend";
import Forecasting from "../components/Forecasting/Forecasting";
import Crud from "../components/Crud/Crud";
import CustomizedSnackbar from "../components/SnackBar/SnackBar";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../features/snackbar/snackbar";
import Settings from "../components/Settings/Settings";
import Occurrences from "../components/Occurrences/Occurrences";
import Places from "../components/Places/Places";

interface Facilities {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  zone: number;
  lat: number;
  lon: number;
  created_by: number;
  updated_by: number;
}

function Home() {
  const dispatch = useAppDispatch();
  const [activeIcons, setActiveIcons] = useState(false);
  const [plumeStyle, setPlumeStyle] = useState(true);
  const { isLoading } = useAppSelector((state) => state.loading);
  const { pointView } = useAppSelector((state) => state.plume);
  const { facilitiesService } = useServices();
  const [facility, setFacility] = useState<Facilities[]>();
  const { isOpen, snackbarData } = useAppSelector((state) => state.snackbar);
  const [icons, setIcons] = useState({
    facility: false,
    emissionSource: false,
    occurrence: false,
    places: false,
  });

  async function fetchFacilities() {
    await facilitiesService
      .fetchFacilities()
      .then((data) => {
        setFacility(data);
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(setSnackbarData({ message: err.message, severity: "error" }));
      });
  }

  useEffect(() => {
    fetchFacilities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (facility) {
      dispatch(updateFacilities(facility));
    }
    // eslint-disable-next-line
  }, [facility]);

  // Render the map with the border points
  return (
    <>
      <MapContainer
        center={[-19.83803464723452, -40.08128970039792]}
        zoom={5}
        scrollWheelZoom={true}
        style={{ height: "100vh", width: "100%" }}
        zoomControl={false}
        doubleClickZoom={false}
        minZoom={5} // Set the minimum zoom level to 5
      >
        <CustomizedSnackbar
          open={isOpen}
          message={snackbarData.message}
          severity={snackbarData.severity}
        />
        <Loader />
        <FloatMenuProps
          setPlumeStyle={setPlumeStyle}
          plumeStyle={plumeStyle}
          setActiveIcons={setActiveIcons}
          activeIcons={activeIcons}
        />
        {!isLoading ? (
          <>
            {plumeStyle === true && pointView === true ? (
              <LinePlumeLayer />
            ) : plumeStyle === true ? (
              <HeatmapLegend />
            ) : (
              <LinePlumeLayer />
            )}
          </>
        ) : (
          <></>
        )}
        <LayersControl />
        {!activeIcons ? (
          <>
            {icons.facility && <FacilitiesIcon />}
            {icons.emissionSource && <EmissionSource />}
            {icons.occurrence && <Occurrences />}
            {icons.places && <Places />}
          </>
        ) : (
          <></>
        )}
        <ZoomControl position="bottomright" />
        <Forecasting />
        {/* <Crud type="Facility" bottom="150px" /> */}
        <Crud type="Place" bottom="78px" />
        <Crud type="EmissionSource" bottom="141px" />
        <Crud type="Occurrence" bottom="204px" />
        <Settings
          setPlumeStyle={setPlumeStyle}
          plumeStyle={plumeStyle}
          setActiveIcons={setActiveIcons}
          activeIcons={activeIcons}
          setIcons={setIcons}
          icons={icons}
        />
      </MapContainer>
    </>
  );
}

export default Home;
