import { Grid } from "@mui/material";
import StyledTextField from "../../StyledTextField";

interface PointProps {
  emissionSource: any;
  setEmissionSource: any;
}

function Point({ emissionSource, setEmissionSource }: PointProps) {
  return (
    <>
      <Grid item xl={12} lg={6}>
        <StyledTextField
          label="Ptemis - Point Emission Rate (g/s)"
          type="number"
          value={emissionSource.typeData?.ptemis}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                ptemis: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xl={12} lg={6}>
        <StyledTextField
          label="Stkhgt - Stack Height (m)"
          type="number"
          value={emissionSource.typeData?.stkhgt}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                stkhgt: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xl={12} lg={4}>
        <StyledTextField
          label="Stktmp - Stack Temperature (min 273 Kelvin)"
          type="number"
          value={emissionSource.typeData?.stktmp}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                stktmp: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xl={12} lg={4}>
        <StyledTextField
          label="Stkvel - Stack Gas Velocity (m/s)"
          type="number"
          value={emissionSource.typeData?.stkvel}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                stkvel: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
      <Grid item xl={12} lg={4}>
        <StyledTextField
          label="Stkdia - Stack Diameter (m)"
          type="number"
          value={emissionSource.typeData?.stkdia}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEmissionSource((prevEmissionSource: { typeData: any }) => ({
              ...prevEmissionSource,
              typeData: {
                ...prevEmissionSource.typeData,
                stkdia: Number(e.target.value),
              },
            }));
          }}
        />
      </Grid>
    </>
  );
}

export default Point;
