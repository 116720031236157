import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useServices } from "../../../context/ServicesContext";
import { setLoading } from "../../../features/loading/loadingSlice";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import StyledTextField from "../StyledTextField";
import { useEffect, useState } from "react";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../features/snackbar/snackbar";
import Dropdown from "../../Dropdown/Dropdown";
import { setPlacesUpdated } from "../../../features/places/placesSlice";

interface Place {
  name: string;
  lat: string;
  lon: string;
  zone: number;
  type: number;
  place_id: string;
  description: string;
}

interface PlaceDisplayProps {
  id?: string;
  setOpen: (value: boolean) => void;
}

function PlaceDisplay({ id, setOpen }: PlaceDisplayProps) {
  const dispatch = useAppDispatch();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { placesUpdated } = useAppSelector((state) => state.places);
  const { placesService } = useServices();
  const [place, setPlace] = useState<Place>({} as Place);
  const [selected, setSelected] = useState<number>(-1);
  const [selectedType, setSelectedType] = useState<number>(-1);
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      placesService
        .getPlacesByID(id)
        .then((data) => {
          setPlace(data);
          setSelected(
            facilitiesStore.findIndex(
              (facility: { id: any }) => facility.id === data.facility
            )
          );
          setSelectedType(data.type - 1);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }, [id]);

  useEffect(() => {
    dispatch(setLoading(true));
    placesService
      .getTypes()
      .then((data) => {
        setTypes(data.result);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    if (selected !== -1) {
      setPlace((prevPlace: any) => ({
        ...prevPlace,
        facility: facilitiesStore[selected].id,
      }));
    }
    if (selectedType !== -1) {
      setPlace((prevPlace: any) => ({
        ...prevPlace,
        type: selectedType + 1,
      }));
    }
  }, [selected, selectedType]);

  return (
    <Card sx={{ maxWidth: "845px" }}>
      <CardHeader title="New Place" sx={{ span: { fontWeight: "bold" } }} />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StyledTextField
              label="Name"
              value={place?.name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setPlace({ ...place, name: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              className="Facility"
              label={"Facility"}
              options={facilitiesStore}
              checkmarks={false}
              selected={selected}
              setSelected={setSelected}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              className="Type"
              label={"Type"}
              options={types ? types : []}
              checkmarks={false}
              selected={selectedType}
              setSelected={setSelectedType}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Latitude"
              type="number"
              value={place?.lat}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setPlace({ ...place, lat: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Longitude"
              type="number"
              value={place?.lon}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setPlace({ ...place, lon: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Description"
              value={place?.description}
              textarea={true}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                setPlace({ ...place, description: e.target.value })
              }
            />
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {id && (
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  marginBottom: "9px",
                  marginRight: "auto",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
                variant="contained"
                color="error"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await placesService
                    .deletePlace(id)
                    .then(() => {
                      dispatch(
                        setSnackbarData({
                          message: "Place Deleted successfully",
                          severity: "success",
                        })
                      );
                      dispatch(setPlacesUpdated(!placesUpdated));
                      dispatch(setLoading(false));
                      setOpen(false);
                    })
                    .catch((err) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Delete
              </Button>
            )}
            <Button
              sx={{
                maxHeight: "30px",
                fontSize: "10px",
                borderRadius: "18px",
                fontWeight: "700",
                marginBottom: "9px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
              color="error"
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            {id ? (
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                }}
                variant="contained"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await placesService
                    .patchPlace(id, place)
                    .then((data) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: "Place Edited Success",
                          severity: "success",
                        })
                      );
                      dispatch(setLoading(false));
                    })
                    .catch((err) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                sx={{
                  maxHeight: "30px",
                  fontSize: "10px",
                  borderRadius: "18px",
                  fontWeight: "700",
                  margin: "0 0 9px 9px",
                }}
                variant="contained"
                onClick={async () => {
                  dispatch(setLoading(true));
                  await placesService
                    .postPlace(place)
                    .then((data) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: "Place Created Success",
                          severity: "success",
                        })
                      );
                      dispatch(setPlacesUpdated(!placesUpdated));
                      dispatch(setLoading(false));
                      setOpen(false);
                    })
                    .catch((err) => {
                      dispatch(setSnackBarOpen(true));
                      dispatch(
                        setSnackbarData({
                          message: err.message,
                          severity: "error",
                        })
                      );
                      dispatch(setLoading(false));
                    });
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PlaceDisplay;
