import { IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import SettingsModal from "./SettingsModal";

interface SettingsProps {
  setPlumeStyle: (value: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (value: boolean) => void;
  activeIcons: boolean;
  setIcons: ({
    facility,
    emissionSource,
    occurrence,
    places,
  }: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  }) => void;
  icons: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  };
}

function Settings({
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
  setIcons,
  icons,
}: SettingsProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title="Settings" placement="left">
        <IconButton
          size="large"
          aria-label="Settings"
          sx={{
            position: "absolute",
            top: "267px",
            right: "15px",
            zIndex: 500,
            bgcolor: "#0A161F",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgb(28, 33, 35)",
            },
          }}
          onClick={() => setOpen(true)}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <SettingsModal
        open={open}
        setOpen={setOpen}
        setPlumeStyle={setPlumeStyle}
        plumeStyle={plumeStyle}
        setActiveIcons={setActiveIcons}
        activeIcons={activeIcons}
        setIcons={setIcons}
        icons={icons}
      />
    </>
  );
}

export default Settings;
