import { createContext, PropsWithChildren, useContext } from "react";
import { FacilitiesService } from "../service/FacilitiesService";
import { ForecastService } from "../service/ForecastService";
import { PlumeService } from "../service/PlumeService";
import { EmissionSourceService } from "../service/EmissionSourceService";
import { OccurrenceService } from "../service/OccurrenceService";
import { PlacesService } from "../service/PlacesService";
import { LoginService } from "../service/LoginService";

export type Services = {
  plumeService: PlumeService;
  facilitiesService: FacilitiesService;
  forecastService: ForecastService;
  emissionSourceService: EmissionSourceService;
  occurrenceService: OccurrenceService;
  placesService: PlacesService;
  loginService: LoginService;
};

const ServicesContext = createContext<Services>(
  undefined as unknown as Services
);

export function ServicesProvider({
  children,
  ...services
}: PropsWithChildren<Services>) {
  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  );
}

export const useServices = (): Services => {
  const context = useContext(ServicesContext);

  if (context == null) {
    throw new Error(
      "Could not find ServicesProvider. Did you forget to wrap the app with <ServicesProvider>?"
    );
  }

  return context;
};
