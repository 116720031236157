import styled from "styled-components";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Marker from "../../../assets/icons/Marker";

export default function Pin() {
  const Container = styled.div`
    position: relative;
    width: 30px;
  `;

  const Icon = styled(HomeWorkIcon)`
    font-size: 20px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
  `;

  return (
    <Container>
      <Marker fill="#676767" />
      <Icon />
    </Container>
  );
}
