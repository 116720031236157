import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppDispatch } from "../../../../../app/hooks";
import { setLoading } from "../../../../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../../../features/snackbar/snackbar";

interface ModelDataProps {
  plumeId: string;
  plumeService: any;
}

export default function ModelData({ plumeId, plumeService }: ModelDataProps) {
  const dispatch = useAppDispatch();

  async function fetchModelData() {
    await plumeService
      .getPlumeDocs(plumeId)
      .then((data: any) => {
        data ?? dispatch(setLoading(false));
      })
      .catch((err: { message: string }) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  return (
    <IconButton
      sx={{
        // maxHeight: { xl: "30px", lg: "20px" },
        maxHeight: { xs: "20px" },
        // fontSize: { xl: ":10px", lg: "8px" },
        fontSize: { xs: "8px" },
        borderRadius: "18px",
        fontWeight: "700",
        marginBottom: "9px",
      }}
      onClick={() => {
        dispatch(setLoading(true));
        fetchModelData();
      }}
    >
      <DownloadIcon />
      Model Data
    </IconButton>
  );
}
