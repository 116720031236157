import { Key, useEffect, useState } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useServices } from "../../context/ServicesContext";
import { setLoading } from "../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../features/snackbar/snackbar";
import Pin from "./Containers/Pin";
import CrudModal from "../Crud/CrudModal";

export default function Places() {
  const dispatch = useAppDispatch();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { placesUpdated } = useAppSelector((state) => state.places);
  const [occurrences, setOccurrences] = useState<any>();
  const { placesService } = useServices();
  const [open, setOpen] = useState(false);
  const [selectedMarkerId, setSelectedMarkerId] = useState<string | null>(null);

  useEffect(() => {
    fetchPlaces();
  }, [facilitiesStore, placesUpdated]);

  async function fetchPlaces() {
    if (facilitiesStore && facilitiesStore[0] && facilitiesStore[0].id) {
      dispatch(setLoading(true));
      await placesService
        .getPlaces(facilitiesStore[0].id)
        .then((resp) => {
          setOccurrences(resp.data);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  const iconMarkup = renderToStaticMarkup(<Pin />);
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  return (
    <>
      {occurrences &&
        occurrences.length > 0 &&
        occurrences.map(
          (
            ref: { lat: number; lon: number; id: string; name: string },
            index: Key | null | undefined
          ) => {
            return (
              <>
                <Marker
                  key={index}
                  icon={customMarkerIcon}
                  position={[ref.lat, ref.lon]}
                  eventHandlers={{
                    click: () => {
                      setSelectedMarkerId(ref.id);
                      setOpen(true);
                    },
                  }}
                >
                  <Tooltip>{ref.name}</Tooltip>
                </Marker>
                {selectedMarkerId === ref.id && open && (
                  <CrudModal
                    type="Place"
                    id={ref.id}
                    open={open}
                    setOpen={setOpen}
                  />
                )}
              </>
            );
          }
        )}
    </>
  );
}
