import { configureStore } from "@reduxjs/toolkit";
import refineryReducer from "../features/refineries/refinerySlice";
import plumeReducer from "../features/plume/plumeSlice";
import facilityReducer from "../features/facilities/facilitiesSlice";
import loadingSlice from "../features/loading/loadingSlice";
import snackbarSlice from "../features/snackbar/snackbar";
import emissionSourceSlice from "../features/emissionSource/emissionSourceSlice";
import placesSlice from "../features/places/placesSlice";
import loginSlice from "../features/login/loginSlice";
import occurrenceSlice from "../features/occurrence/occurrenceSlice";

export const store = configureStore({
  reducer: {
    refinery: refineryReducer,
    plume: plumeReducer,
    facilities: facilityReducer,
    loading: loadingSlice,
    snackbar: snackbarSlice,
    emissionSource: emissionSourceSlice,
    places: placesSlice,
    login: loginSlice,
    occurrence: occurrenceSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
