import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmissionSource } from "../../interfaces/EmissionSource";

const emissionSourceUpdated: boolean = false;

const selectedEmissionSources: any = [];

const emissionSourceSlice = createSlice({
  name: "emissionSource",
  initialState: { emissionSourceUpdated, selectedEmissionSources },
  reducers: {
    setEmissionSourceUpdated: (state, action: PayloadAction<boolean>) => {
      state.emissionSourceUpdated = action.payload;
    },
    setSelectedEmissionSources: (state, action: PayloadAction<any>) => {
      state.selectedEmissionSources = action.payload;
    },
  },
});

export const { setEmissionSourceUpdated, setSelectedEmissionSources } =
  emissionSourceSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state

export default emissionSourceSlice.reducer;
