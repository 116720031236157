import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface Facilities {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  created_by: number;
  updated_by: number;
}

interface DropdownProps {
  label: string;
  options: Facilities[] | { name: string }[];
  checkmarks: boolean;
  selected: number;
  setSelected: Dispatch<SetStateAction<number>> | ((arg0: any) => void);
  disabled?: boolean;
  className?: string;
}

export default function Dropdown({
  label,
  options,
  selected,
  setSelected,
  disabled,
  className,
}: DropdownProps) {
  const selectionChangeHandler = (event: any) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl
      sx={{ display: "flex", pb: 2, width: "100%" }}
      disabled={disabled}
    >
      <InputLabel
        htmlFor={className}
        sx={{
          // fontSize: { xl: "16px", lg: "14px" },
          fontSize: { xs: "14px" },
          color: "rgba(255, 255, 255, 0.7)",
          "&.Mui-focused": {
            color: "rgba(255, 255, 255, 0.7)",
          },
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        id={className}
        sx={{
          width: "100%",
          minHeight: "38px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
          },
          "& .MuiSelect-select": {
            // padding: { xl: "8px", lg: "5px 8px" },
            padding: { xs: "5px 8px" },
            fontSize: "10px",
          },
        }}
        label={label}
        notched
        value={selected !== -1 ? selected : ""}
        onChange={selectionChangeHandler}
      >
        <MenuItem disabled defaultValue=""></MenuItem>
        {options.map((value, index) => {
          return (
            <MenuItem key={index} value={index}>
              {value.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
