import { Grid } from "@mui/material";
import StyledTextField from "../../StyledTextField";

interface TextAreasProps {
  emissionSource: any;
  setEmissionSource: any;
}

function TextAreas({ emissionSource, setEmissionSource }: TextAreasProps) {
  return (
    <>
      <Grid item xs={12}>
        <StyledTextField
          label="Building"
          value={emissionSource?.building}
          textarea={true}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setEmissionSource({
              ...emissionSource,
              building: e.target.value,
            })
          }
        />
      </Grid>
    </>
  );
}

export default TextAreas;
