import { Dispatch, SetStateAction } from "react";
import { grey } from "@mui/material/colors";
import { Container, IconButton, Typography } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { parse, format } from "date-fns";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../app/hooks";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

interface MenuNavProps {
  open: boolean;
  setOpen: ActionCreatorWithPayload<boolean, "plume/setOpen">;
  plumeDate: string;
}

export default function MenuNav({ open, setOpen, plumeDate }: MenuNavProps) {
  const dispatch = useAppDispatch();
  let dayDate: Date | string;

  plumeDate !== ""
    ? (dayDate = format(
        parse(plumeDate, "yyMMddHHmm", new Date()),
        "d 'de' MMM 'de' yyyy',' HH:mm 'BRT'"
      ))
    : (dayDate = format(new Date(), "d 'de' MMM 'de' yyyy',' HH:mm 'BRT'"));

  return (
    <Container
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "48px",
        backgroundColor: "#0A161F",
      }}
    >
      <Typography sx={{ color: "white" }} variant="body1">
        {dayDate.toString()}
      </Typography>
      <IconButton
        onClick={() => dispatch(setOpen(!open))}
        sx={{
          color: "white",
          backgroundColor: open ? "rgba(255, 255, 255, 0.1)" : grey[900],
          borderRadius: "6px",
          padding: "4px",
        }}
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </IconButton>
    </Container>
  );
}
