import React, { Dispatch, SetStateAction, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format, parse } from "date-fns";
import {
  Button,
  TablePagination,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useServices } from "../../../context/ServicesContext";
import { setLoading } from "../../../features/loading/loadingSlice";
import EditIcon from "@mui/icons-material/Edit";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../features/snackbar/snackbar";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setOpen,
  setStoredData,
  updatePlume,
} from "../../../features/plume/plumeSlice";

interface TableProps {
  forecast: any;
  forecastDay: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}

function createData(
  DateTime: string,
  WeatherTemperature: string,
  WindDirection: string,
  Windspeed: string,
  Precipitation: string,
  BarometricPressure: string,
  PercentPrecipitation: string,
  RelativeHumidity: string
) {
  return {
    DateTime,
    WeatherTemperature,
    WindDirection,
    Windspeed,
    Precipitation,
    BarometricPressure,
    PercentPrecipitation,
    RelativeHumidity,
  };
}

function HourlyForecastTable({
  forecast,
  forecastDay,
  setModalOpen,
}: TableProps) {
  const dispatch = useAppDispatch();
  const { storedData } = useAppSelector((state) => state.plume);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedPollutant, setSelectedPollutant] = React.useState("Pollutant");
  const { plumeService, facilitiesService } = useServices();
  const [pollutants, setPollutants] = React.useState([{ name: "Pollutant" }]);
  const [emissionSources, setEmissionSources] = React.useState<any[]>([]);

  const rows: {
    DateTime: string;
    WeatherTemperature: string;
    WindDirection: string;
    Windspeed: string;
    Precipitation: string;
    BarometricPressure: string;
    PercentPrecipitation: string;
    RelativeHumidity: string;
  }[] = [];

  let hours = 0;
  let day = forecastDay.substr(0, forecastDay.length - 2);

  Object.keys(forecast).map(() => {
    if (hours < 24) {
      let key = hours < 10 ? day + "0" + hours : day + hours.toString();
      if (forecast[key] !== undefined) {
        rows.push(
          createData(
            format(parse(key, "yyyyMMddHH", new Date()), "cccc HH:mm"),
            forecast[key].temp,
            forecast[key].wd,
            forecast[key].ws,
            forecast[key].pamt,
            forecast[key].pres,
            forecast[key].pamt,
            forecast[key].rh
          )
        );
      }
      hours += 1;
    }
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  async function fetchEmissionSource() {
    if (storedData.facilityID) {
      await facilitiesService
        .fetchEmissionSource(storedData.facilityID)
        .then((resp) => {
          setEmissionSources(resp.data);
          resp.data.map((emission: any) => {
            setPollutants((prev: any) => {
              const uniquePollutants = new Set(prev.map((p: any) => p.name));
              if (!uniquePollutants.has(emission.pollutant)) {
                return [...prev, { name: emission.pollutant }];
              }
              return prev;
            });
          });
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
        });
    }
  }

  useEffect(() => {
    fetchEmissionSource();
    console.log("entrou");
  }, []);

  async function fetchPlume(date: any) {
    let startEndDate =
      format(parse(forecastDay, "yyyyMMddHH", new Date()), "yyyy-MM-dd") +
      date.slice(date.indexOf(" "), -3);

    dispatch(setLoading(true));
    let sourceIds = emissionSources
      ?.filter(
        (emission: { pollutant: string }) =>
          emission.pollutant === selectedPollutant
      )
      ?.map((emission: any) => emission.id);
    await plumeService
      .fetchPlume(
        storedData.facilityID,
        { sources: sourceIds },
        startEndDate,
        startEndDate,
        1,
        1
      )
      .then((resp) => {
        if (resp.result.length === 0) {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: "No plume data found",
              severity: "error",
            })
          );
        }
        dispatch(updatePlume(resp));
        dispatch(setOpen(true));
        dispatch(
          setStoredData({
            ...storedData,
            startDate: startEndDate,
            endDate: startEndDate,
            emissionSources: sourceIds,
            time: 1,
            pollutant: selectedPollutant, // Add the selected pollutant to the stored data
          })
        );
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: { xs: "8px" } }}>Date/Time</TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Weather & Temperature
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Wind Direction
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Windspeed
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Precipitation
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Barometric Pressure
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Percent of Precipitation
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Relative Humidity
            </TableCell>
            <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
              Pollutant/View on Map
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.map((row, index) => (
            <TableRow
              key={row.DateTime}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: { xs: "8px" } }}
              >
                {row.DateTime}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.WeatherTemperature}°C
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.WindDirection}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.Windspeed} m/s
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.Precipitation} mm
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.BarometricPressure} hpa
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.PercentPrecipitation}%
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {row.RelativeHumidity}%
              </TableCell>
              <TableCell align="center" sx={{ fontSize: { xs: "8px" } }}>
                {selectedPollutant !== "Pollutant" ? (
                  <>
                    <Button
                      sx={{
                        width: { xs: "83px" },
                        maxHeight: { xs: "20px" },
                        fontSize: { xs: "8px" },
                        borderRadius: "18px",
                        fontWeight: "700",
                        textTransform: "none",
                      }}
                      variant="contained"
                      onClick={() => {
                        setModalOpen(false);
                        fetchPlume(row.DateTime);
                      }}
                    >
                      View on Map
                    </Button>
                    <IconButton
                      sx={{
                        maxWidth: { xs: "10px" },
                        maxHeight: { xs: "10px" },
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        setSelectedPollutant("Pollutant");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                ) : (
                  <Select
                    value={selectedPollutant}
                    onChange={(event) =>
                      setSelectedPollutant(event.target.value)
                    }
                    disabled={pollutants.length === 1}
                    sx={{
                      width: { xs: "83px" },
                      maxHeight: { xs: "20px" },
                      fontSize: { xs: "8px" },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSelect-select": {
                        padding: { xs: "5px 8px" },
                        fontSize: "10px",
                      },
                    }}
                    notched
                  >
                    {pollutants.map((pollutant) => (
                      <MenuItem value={pollutant.name}>
                        {pollutant.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default HourlyForecastTable;
